import { gql } from "@/gql";

export const GET_LONG_RUNNING_SERVICES = gql(`
query GET_LONG_RUNNING_SERVICES($limit: Int, $offset: Int, $sort: InputSort, $filter: InputFilter) {
  getLongRunningServices(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
    id
    taskType { 
      alias
    }
    taskStatus { 
      alias
    }
    collocation {
      id
      name
    }
  }
}
`)


export const CREATE_DATA_BROWSER_TASK = gql(`
mutation CREATE_DATA_BROWSER_TASK($dmCollocationId: String!, $logDmVolumeMountAlias: String!) {
  spawnDataBrowserService(dmCollocationId: $dmCollocationId, logDmVolumeMountAlias: $logDmVolumeMountAlias) {
    message
    status
  }
}
`)

export const STOP_DATA_BROWSER_SERVICE = gql(`
  mutation STOP_DATA_BROWSER_SERVICE($id: ID!){
    stopDataBrowserService(id: $id) {
      message
      status
    }
  }
`)

export const RESTART_DATA_BROWSER_SERVICE = gql(`
  mutation RESTART_DATA_BROWSER_SERVICE($id: ID!){
    restartDataBrowserService(id: $id) {
      message
      status
    }
  }
`)

export const CREATE_GEO_CONVERSION_TASK = gql(`
mutation createGeoConversionServiceTask($dmVolumeMountAlias: String!, $dmCollocationId: String!) {
  spawnGeoService(dmVolumeMountAlias: $dmVolumeMountAlias, dmCollocationId: $dmCollocationId) {
    message
    status
  }
}
`)

export const STOP_GEO_CONVERSION_SERVICE = gql(`
  mutation STOP_GEO_CONVERSION_SERVICE($id: ID!){
    stopGeoConversionService(id: $id) {
      message
      status
    }
  }
`)
