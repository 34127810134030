import moment from 'moment'
import { DataTablePageEvent, DataTableSortEvent } from 'primevue/datatable';
import { ref, Ref } from 'vue'
import { LocationQueryValueRaw } from 'vue-router';
import { useRouter } from 'vue-router';

interface FilterQuery {
  value: string | null | LocationQueryValueRaw[] | LocationQueryValueRaw;
  matchMode: string;
  pretty: string;
}


interface filterObject {
  [key: string]: FilterQuery;
}

interface useTablesConfig {
  filters: filterObject,
  rowsPerPageOptions?: number[],
  defaultSortField?: string | ((item: any) => string) | undefined,
  lazy?: boolean
}

export function useTables(tableConfig: useTablesConfig) {
  const tableFilters: Ref<filterObject> = ref(tableConfig.filters)
  /*
  const lazyParams: Ref<{
    filters?: any,
    first: number,
    sortOrder?: number,
    rows?: number
  }> = ref({ 
    first: 0
  })
  */
  const lazyParams: Ref<any> = ref({})
  const lazy: Ref<boolean> = ref(tableConfig.lazy ?? false)

  const router = useRouter()
  
  // todo add option of lazy loading and cb
  const first = ref(0)
  const _query = router.currentRoute.value.query
  const _defaultSortField: string | ((item: any) => string) | undefined = prettyFilterKeyToKey(_query.sortField) ? prettyFilterKeyToKey(_query.sortField) : ((tableConfig.defaultSortField) ? tableConfig.defaultSortField : 'id')
  const _defaultSortOrder = prettyFilterKeyToKey(_query.sortField) ? ((_query.sortOrder === 'desc') ? -1 : 1): -1
  const defaultSortField: Ref<string | ((item: any) => string) | undefined> = ref(_defaultSortField)
  const defaultSortOrder = ref(_defaultSortOrder)

  // default global configuration of tables
  const rowsPerPageOptions = tableConfig.rowsPerPageOptions ?? [25, 100]
  
  const rows: Ref<number> = ref(
    (typeof _query.limit === 'string' && !isNaN(parseInt(_query.limit))) ?
      parseInt(_query.limit) : 25
  )

  function prettyFilterKeyToKey(prettyKey: LocationQueryValueRaw | LocationQueryValueRaw[]): string | ((item: any) => string) | undefined {
    if (!prettyKey) {
      return undefined
    }
    for (const f of Object.keys(tableConfig.filters)) {
      if (tableConfig.filters[f].pretty === prettyKey) {
        return f
      }
    }
    return undefined
  }

  function makeStrong(text: string, filter: string) {
    if (typeof text === 'undefined')
      return ``
    if (text && tableFilters && tableFilters.value[filter] && typeof tableFilters.value[filter].value === 'string') {
      const _value = tableFilters.value[filter].value
      if (typeof _value === 'string') {
        const index = text.toLowerCase().search(_value.toLowerCase())
        return text.substring(0, index)
          + "<strong>"
          + text.substring(index, index + _value.length)
          + "</strong>"
          + text.substring(index + _value.length)
      } else {
        return `${text}`
      }
    } else {
      return `${text}`
    }
  }
  
  function formatDate(timestamp: string) {
    return moment.unix((parseInt(timestamp) / 1000)).format("DD.MM.YY HH:mm")
  }

  function loadLazyData() {
  }

  function onPage(event: DataTablePageEvent) {
    console.log('onPage', event)
    lazyParams.value = event
    loadLazyData()
  }

  function onSort(event: DataTableSortEvent) {
    console.log('onSort', event)
    lazyParams.value = event
    loadLazyData()
  }

  function onFilter(/*event: DataTableFilterEvent*/) {
    //console.log('onFilter', event)
    lazyParams.value.filters = tableFilters.value 
    lazyParams.value.first = 0
    first.value = 0
    loadLazyData()
  }
   

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function serializeQuery() {
    
    const fetchQuery: {
      [key: string]: string | number | LocationQueryValueRaw | LocationQueryValueRaw[]
    } = {
      limit: lazyParams.value.rows,
      offset: lazyParams.value.first,
      sortOrder: lazyParams.value.sortOrder === 1 ? 'asc' : 'desc',
      sortField: ''
    }

    
    for (const f of Object.keys(tableConfig.filters)) {
      if (tableFilters.value[f].value !== null) {
        fetchQuery[tableFilters.value[f].pretty] = tableFilters.value[f].value
      }
      
      if (lazyParams.value.sortField && lazyParams.value.sortField === f) {
        fetchQuery.sortField = tableConfig.filters[f].pretty
      }
    }
    
    router.replace({ query: fetchQuery })
    
    return fetchQuery
  }

  return {
    formatDate,
    makeStrong,
    tableFilters,
    lazyParams,
    onPage,
    onFilter,
    onSort,
    rows,
    defaultSortField,
    defaultSortOrder,
    rowsPerPageOptions,
    lazy,
  }
}