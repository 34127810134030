import { gql } from "@/gql";

export const GET_CDN_ORIGINS = gql(`
query GET_CDN_ORIGINS($limit: Int, $offset: Int, $sort: InputSort, $filter: InputFilter) {
    getCdnOrigins(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
      cdnOrigins {
        id
        alias
        cdnOriginType {
          alias
        }
        status {
          alias
          name
        }
        dmCollocationId
        error
      }
      pageInfo {
        limit
        offset
        total
      }
    }
  }
`)

export const STOP_CDN_ORIGIN_SERVICE = gql(`
  mutation STOP_CDN_ORIGIN_SERVICE($id: Int!){
    stopCdnOriginService(id: $id) {
      id
      alias
      status {
        alias
        name
      }
    }
  }
`)

export const RESTART_CDN_ORIGIN_SERVICE = gql(`
  mutation RESTART_CDN_ORIGIN_SERVICE($id: Int!){
    restartCdnOriginService(id: $id) {
      id
      alias
      status {
        alias
        name
      }
    }
  }
`)