import { initalizeApp } from "./initalizeApp"
import { useConfig } from "./composables/useConfig"

/**
 * @description Init Honeydew Web UI
 */
const init = async () => {
  if (window.Honeydew) {
    window.Honeydew = null
  }
  const { loadConfig } = useConfig()
  const config = await loadConfig()
  initalizeApp(config)
}

init()
