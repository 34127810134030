import { gql } from 'graphql-tag'

export const GET_PROJECTS = gql`
  query GET_PROJECTS {
    getProjects {
      projects {
        id
        name
        notes
        externalUri
        attributes
        archived
      }
      pageInfo {
        limit
        offset
        total
      }
      timestamp
    }
  }
`

export const GET_PROJECT = gql`
  query GET_PROJECT($projectId: Int!) {
    getProject(id: $projectId) {
      id
      name
      notes
      externalUri
      attributes
      archived
    }
  }
`
