<template>
  <div class="vue3-progress-bar-container" :class="show ? 'show': 'hide'"  active="true" >
    <div class="vue3-progress-bar" style="transform: translate3d(-1%, 0px, 0px);"></div>
  </div>  
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean
    }
  },
  setup() {

  }
}
</script>