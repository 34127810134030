import { Ref, computed } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { GET_CDN_ORIGINS, RESTART_CDN_ORIGIN_SERVICE, STOP_CDN_ORIGIN_SERVICE } from '@/apollo/queries/cdn'

/**
 * @description
 * @interface mutationResult
 */
interface CdnOriginMutationResult {
  /**
   * @description success
   * @type {boolean}
   * @memberof mutationResult
   */
  success?: boolean,
  /**
   * @description error
   * @type {*}
   * @memberof mutationResult
   */
  error?: any,
}

export function useCdn() {
  /**
   * @param {Ref<{
   *     limit?: number
   *     offset?: number
   *     search?: string
   *   }>} input
   * @return {*} 
   */
  function getCdnOrigins(input: Ref<{
    limit?: number
    offset?: number
    search?: string
  }>) {
    const { result, error, loading, refetch } = useQuery(GET_CDN_ORIGINS, input, {
      errorPolicy: 'all',
      pollInterval: 2_000
    })
    const data = computed(() => result?.value?.getCdnOrigins.cdnOrigins ?? [])
    return { data, error, loading, refetch }
  }

  const { mutate: stopCdnOriginServiceMutation } = useMutation(STOP_CDN_ORIGIN_SERVICE)
  /**
   * @param {string} alias
   * @return {*}  {Promise<CdnOriginMutationResult>}
   */
  async function stopCdnOriginService(id: number): Promise<CdnOriginMutationResult> {
    try {
      await stopCdnOriginServiceMutation({ id })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  const { mutate: restartCdnOriginServiceMutation } = useMutation(RESTART_CDN_ORIGIN_SERVICE)
  /**
   * @param {string} id
   * @return {*}  {Promise<CdnOriginMutationResult>}
   */
  async function restartCdnOriginService(id: number): Promise<CdnOriginMutationResult> {
    try {
      await restartCdnOriginServiceMutation({ id })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  return { getCdnOrigins, stopCdnOriginService, restartCdnOriginService }
}
