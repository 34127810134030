import { gql } from "@/gql"

export const CREATE_PROJECT = gql(`
  mutation CREATE_PROJECT($name: String!, $notes: String, $externalUri: String, $attributes: JSONObject) {
    createProject(name: $name, notes: $notes, externalUri: $externalUri, attributes: $attributes) {
      id
      name
      notes
      externalUri
      attributes
    }
  }
`)

export const UPDATE_PROJECT = gql(`
  mutation UPDATE_PROJECT($id: Int!, $name: String!, $notes: String, $externalUri: String, $attributes: JSONObject, $archived: Boolean) {
    updateProject(id: $id, name: $name, notes: $notes, externalUri: $externalUri, attributes: $attributes, archived: $archived) {
      id
      name
      notes
      externalUri
      attributes
    }
  }
`)
