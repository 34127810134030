export const evaluateFilter = (feature, filter) => {
  const condition = filter.condition
  if (condition) {
    let result = false
    for (const orCondition of condition) {     
      let and = true  
      let reg = false      
      for (const andCondition of orCondition) {
        const text = feature.properties[andCondition.key] + ''
        const regex = new RegExp( andCondition.value, 'g' )
        switch (andCondition.operator) {          
        case '=':
          and &&= feature.properties[andCondition.key] === andCondition.value
          break
        case '!=':
          and &&= feature.properties[andCondition.key] !== andCondition.value
          break
        case '<':
          and &&= feature.properties[andCondition.key] < andCondition.value
          break
        case '>':
          and &&= feature.properties[andCondition.key] > andCondition.value
          break
        case '<=':
          and &&= feature.properties[andCondition.key] <= andCondition.value
          break
        case '>=':
          and &&= feature.properties[andCondition.key] >= andCondition.value
          break
        case 'REGEX':
          reg = text.match(regex)
          if (reg === null)
            reg = false
          else 
            reg = true
          and &&= reg
          break
        }
      }
      result ||= and
    }
    return result
  }
  else {  
    return true
  }
}

