import { PopulationTypeAlias } from "@/gql/graphql";
import { ref } from "vue";

export function useLogFiles() {
  
  function populationLogFilePath(populationTypeAlias: PopulationTypeAlias) {
    let logFilePath = ''
    
    switch(populationTypeAlias) {
    case PopulationTypeAlias.Ingest:
      logFilePath = 'vadstena-input/vadstena-ingest.log'
      break
    case PopulationTypeAlias.Dilution:
      logFilePath = 'vadstena-input/input-dilute.log'
      break
    }

    return logFilePath
  }

  
  function inputQcLogFilePath() {
    return 'vadstena-input/qc/scene-input-qc.log'
  }

  return {
    inputQcLogFilePath,
    populationLogFilePath
  }
}
