import VolumeList from '@/components/Admin/DataVolumes/Volumes/VolumeList.vue'
import VolumeDetail from '@/components/Admin/DataVolumes/Volumes/VolumeDetail.vue'
import VolumeRegister from '@/components/Admin/DataVolumes/Volumes/VolumeRegister.vue'
import VolumeUpdate from '@/components/Admin/DataVolumes/Volumes/VolumeUpdate.vue'
import { RouteRecordRaw, useRouter } from 'vue-router'

export const dataVolumesRoutes: RouteRecordRaw[] = [
  {
    path: 'storage/:storageId',
    name: 'storage-volume-list',
    component: VolumeList,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Storage', name: 'storage-volume-list' }
      ]
    },
    props: true,
  },
  {
    path: 'storage/:storageId/register-volume',
    name: 'storage-volume-register',
    component: VolumeRegister,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Storage', name: 'storage-volume-list' },
        { label: 'Register volume' },
      ]
    },
    props: true
  },
  {
    path: 'storage/:storageId/volume/:volumeMountAlias',
    name: 'storage-volume-detail',
    component: VolumeDetail,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Storage', name: 'storage-volume-list' },
        { label: 'Volume detail' },
      ]
    },
    props: true
  },
  {
    path: 'storage/:storageId/volume/:volumeMountAlias/update',
    name: 'storage-volume-update',
    component: VolumeUpdate,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Storage', name: 'storage-volume-list' },
        { label: 'Volume detail', name: 'storage-volume-detail' },
        { label: 'Update volume' },
      ]
    },
    props: true
  },
  /*
  {
    path: 'storage/:volume_id',
    name: 'input-detail',
    component: InputDetail,
    meta: {
      can: 'is-admin'
    },
    props: true
  }
  */
]
