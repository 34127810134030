import { useMutation } from '@vue/apollo-composable'
import { CreateFileProviderTaskMutationVariables, CreateGeoConversionServiceTaskMutationVariables, CreateImagePoseServiceTaskMutationVariables, Create_Data_Browser_TaskMutationVariables } from '@/gql/graphql'
import {
  // CANCEL_TASK,
  CREATE_FILE_PROVIDER_TASK,
  CREATE_IMAGE_POSE_SERVICE_TASK
} from '@/apollo/queries/data_mgmt'
import { CREATE_DATA_BROWSER_TASK, CREATE_GEO_CONVERSION_TASK } from '@/apollo/queries/long_running_services'

export function useTasks() {
  // const { mutate: cancelTaskMutation } = useMutation(CANCEL_TASK)
  const { mutate: createFileProviderMutation } = useMutation(CREATE_FILE_PROVIDER_TASK)
  const { mutate: createImagePoseServiceMutation } = useMutation(CREATE_IMAGE_POSE_SERVICE_TASK)
  const { mutate: createDataBrowserServiceMutation } = useMutation(CREATE_DATA_BROWSER_TASK)
  const { mutate: createGeoConversionServiceMutation } = useMutation(CREATE_GEO_CONVERSION_TASK)

  /**
   * @description Calls mutation for creating api task
   * @param {CreateFileProviderTaskMutationVariables} config
   * @return {*}  {Promise<createFileProviderMutationResult>}
   */
  async function createFileProviderServiceTask(config: CreateFileProviderTaskMutationVariables): Promise<any> {
    const res = await createFileProviderMutation(config)
    return res
  }

  /**
   * @description Calls mutation for creating api task
   * @param {CreateImagePoseServiceTaskMutationVariables} config
   * @return {*}  {Promise<createImagePoseServiceMutationResult>}
   */
  async function createImagePoseServiceTask(config: CreateImagePoseServiceTaskMutationVariables): Promise<any> {
    const res = await createImagePoseServiceMutation(config)
    return res
  }

  /**
   * @description Calls mutation for creating api task
   * @param {CreateDataBrowseTaskMutationVariables} config
   * @return {*}  {Promise<createDataBrowseServiceMutationResult>}
   */
  async function createDataBrowserServiceTask(config: Create_Data_Browser_TaskMutationVariables): Promise<any> {
    const res = await createDataBrowserServiceMutation(config)
    return res
  }

  /**
   * @description Calls mutation for creating api task
   * @param {CreateGeoConversionServiceTask} config
   * @return {*}  {Promise<createGeoConversionServiceMutationResult>}
   */
  async function createGeoConversionServiceTask(config: CreateGeoConversionServiceTaskMutationVariables): Promise<any> {
    const res = await createGeoConversionServiceMutation(config)
    return res
  }

  return {
    // getTasks,
    // getDmTasks,
    createFileProviderServiceTask,
    createImagePoseServiceTask,
    createDataBrowserServiceTask,
    createGeoConversionServiceTask,
  }
}
