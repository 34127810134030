import { gql } from "@/gql"

/**
 * 
 */
export const GET_API_VERSION = gql(`
  query GET_API_VERSION {
    getApiVersion {
      version
    }
  }
`)