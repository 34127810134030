// scene
import SceneList from '@/components/Admin/Processing/Scene/SceneList.vue'

// target
import TargetList from '@/components/Admin/Processing/Target/TargetList.vue'
import { RouteRecordRaw } from 'vue-router'

export const processingRoutes: RouteRecordRaw[] = [
  {
    path: 'processing/scene',
    name: 'scene-list',
    component: SceneList,
    meta: {
      can: 'is-admin',
      breadcrumb: [{ label: 'All scenes' }]
    }
  },
  {
    path: 'processing/target',
    name: 'target-list',
    component: TargetList,
    meta: {
      can: 'is-admin',
      breadcrumb: [{ label: 'Targets' }]
    }
  },
]
