import { gql } from "@/gql"

export const UDPATE_PRESET = gql(`
  mutation UDPATE_PRESET($key: ID!, $value: JSONObject) {
    updatePreset(key: $key, value: $value) {
      key
      value
      presetType {
        alias
      }
    } 
  }
`)

export const CREATE_PRESET = gql(`
  mutation CREATE_PRESET($key: ID!, $presetTypeAlias: PresetTypesEnum, $value: JSONObject) {
    createPreset(key: $key, presetTypeAlias: $presetTypeAlias, value: $value) {
      key
      value
      presetType {
        alias
      }
    }
  }
`)

export const DELETE_PRESET = gql(`
  mutation DELETE_PRESET($key: ID!) {
    deletePreset(key: $key) {
      status
      message
    }
  }
`)
