import router from "@/router" 
import { useAuth } from "@/composables/useAuth"

import { createAcl, defineAclRules } from 'vue-simple-acl'

const { user } = useAuth()

const rules = () => defineAclRules((setRule) => {
  setRule('is-admin', (user) =>
    user && user.is_admin
  )
  setRule('is-annotator', (user) =>
    user && (user.is_admin || user.is_annotator)
  )
})



const simpleAcl = createAcl({
  user: user.value, // short for user: user
  rules, // short for rules: rules
  router, // OPTIONAL, short for router: router 
  onDeniedRoute: '/404'
})

export default simpleAcl
