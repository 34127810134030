import L from 'leaflet'
import { evaluateFilter } from '../SceneFilter.js'

export const getGeoJson = async (root,dataLayer,key,value,layerProperties,getInfobyFeature) => {
  return await fetch(`${root}/${dataLayer.url}`).then((response) => {
    if (!response.ok){
      return null;
    } 
    else {
      return response.json()
    }
  })
    .then((data) => {
      if (data) {
        loadGeoJson(data,key,value,layerProperties,getInfobyFeature)
      }
    })
    .catch(error => {
      console.log(`Failed to load ${root}/${dataLayer.url}`)
    }); 
}  

export const loadGeoJson = (data,key,value,layerProperties,getInfobyFeature) => {
  const overlay = value.overlays[key]
  if (!overlay)
    return
  function style (feature) {        
    const s = value.overlays[key].style
    const p = feature.properties
    if (s && s.key && p && (p[s.key] === 0 || p[s.key]) && s.values && s.values[p[s.key]]) {
      return s.values[p[s.key]]
    }
    else if (s && !s.key && s.value) {
      return s.value
    }
    else {
      return {
        weight: 1,
        opacity: 1,
        color: 'white',          
        fill: true,
        fillOpacity: 0.01
      }
    }
  }            

  function highlightFeature (e) {
    const layer = e.target        
    const properties = layer?.feature?.properties
    /*
    layerProperties.menuItems = []   
    for (const property of Object.keys(properties)) {
      layerProperties.menuItems.push({
        key: property,
        value: properties[property]
      })
    }
    */
    const s = value.overlays[key].style
    if (s && s.highlightFeature) {
      layer.setStyle(s.highlightFeature)
    }
    else {
      layer.setStyle({
        color: '#00f',
        fillOpacity: 0.25
      })
    }
    layer.bringToFront()
  }
  var myIcon = L.divIcon({
    html: '<i class="fas fa-map-pin"></i>',
    iconSize: [20, 20],
    className: 'dummy' 
  })
  
  overlay.data = data
  var counter = 0
  const filter = overlay.filter
  var currentZoom = value.map.getZoom()
  const reductionZoom = filter && filter.reductionCondition && filter.reductionCondition.zoom ? filter.reductionCondition.zoom : currentZoom
  const everyNthFeature = filter && filter.reductionCondition && filter.reductionCondition.everyNthFeature ? filter.reductionCondition.everyNthFeature : null
  overlay.options.pane = `pane${400 + overlay.zIndex}`
  overlay.tileLayer = L.geoJSON(data, {
    ...value.overlays[key].options,
    pointToLayer: function(gp, latlng) {
      const marker = L.circleMarker(latlng, {
        radius: 4,
        pane: `pane${400 + overlay.zIndex}`,
      })
      return marker
    },
    style,
    onEachFeature,
    filter: (feature) => {
      //return !filter ? true : ((everyNthFeature && currentZoom < reductionZoom ? counter++ % everyNthFeature === 0 : true) && evaluateFilter(feature, filter))
      return !filter ? true : ((everyNthFeature ? counter++ % everyNthFeature === 0 : true) && evaluateFilter(feature, filter))
    }
  })

  /*
  footprintsLayer = geoJsonVT(data, {
    maxZoom: 14,
    tolerance: 3,
    extent: 4096,
    buffer: 64,
    debug: 0,
    style: style2,
    onEachFeature,      
    indexMaxZoom: 4,
    indexMaxPoints: 100000,
    solidChildren: false
  })
  */
  function resetHighlight (e) {
    value.overlays[key].tileLayer.resetStyle(e.target)
    //layerProperties.menuItems = [] 
  }

  function getNearest(feature, latlng, overlay, delta = null) {
    const nearest = []
    
    let x = 0
    let y = 0
    for (let i = 0; i < feature.geometry.coordinates[0].length - 1; i++) {
      const point = feature.geometry.coordinates[0][i]
      x += point[0]
      y += point[1]
    }
    x/=(feature.geometry.coordinates[0].length-1)
    y/=(feature.geometry.coordinates[0].length-1)        
    
    let maxDistance = -1
    let maxDistanceIndex = null
    for (let i = 0; i < feature.geometry.coordinates[0].length - 1; i++) {
      const dist = Math.sqrt(Math.pow(x - feature.geometry.coordinates[0][i][0],2.0) + Math.pow(y - feature.geometry.coordinates[0][i][1],2.0))
      if (dist > maxDistance) {
        maxDistance = dist
        maxDistanceIndex = i
      }
    }

    const deltaLng = !delta ? maxDistance : delta
    const deltaLat = !delta ? maxDistance : delta

    let lng = latlng.lng
    let lat = latlng.lat                

    for (const layerFeature of overlay.data.features) {
      let _x = 0
      let _y = 0
      for (let i = 0; i < layerFeature.geometry.coordinates[0].length - 1; i++) {
        const _point = layerFeature.geometry.coordinates[0][i]
        _x += _point[0]
        _y += _point[1]
      }
      _x/=(layerFeature.geometry.coordinates[0].length-1)
      _y/=(layerFeature.geometry.coordinates[0].length-1)
      if (Math.abs(lng - _x) < deltaLng && Math.abs(lat - _y) < deltaLat) {
        nearest.push(layerFeature)
      }          
    }
    return nearest.sort(function(a, b) {
      return (a.properties['ViewId'] > b.properties['ViewId']) ? 1 : ((a.properties['ViewId'] < b.properties['ViewId']) ? -1 : 0);
    });
  }

  function getNearestPoint(feature, latlng, overlay, delta = null) {
    const nearest = []
    
    const lng = feature.geometry.coordinates[0][0]
    const lat = feature.geometry.coordinates[0][1]              

    for (const layerFeature of overlay.data.features) {
      if (layerFeature.properties.Kind !== 'aerial') {
        continue
      }
      const _x = layerFeature.geometry.coordinates[0][0]
      const _y = layerFeature.geometry.coordinates[0][1]      
      if (Math.abs(lng - _x) < delta && Math.abs(lat - _y) < delta) {
        nearest.push(layerFeature)
      }          
    }
    return nearest.sort(function(a, b) {
      return (a.properties['ViewId'] > b.properties['ViewId']) ? 1 : ((a.properties['ViewId'] < b.properties['ViewId']) ? -1 : 0);
    });
  }

  function click (e) {
    //map.fitBounds(e.target.getBounds())        
    const options = e.sourceTarget?.options ?? e.target.options        
    let layerKey = ''
    const X = e.target.feature.properties['EyeX']
    const Y = e.target.feature.properties['EyeY']        
    const feature = e.target.feature    
    for (const key of Object.keys(value.overlays)) {
      if (('pane' + (400+value.overlays[key].zIndex)) === options.pane) {
        layerKey = key
      }
    }
    const overlay = value.overlays[layerKey]
    switch(layerKey) {      
    case 'imageFootprints': 
      value.filterLayer = layerKey
    
      if (value.shiftPressed) {
        //const data = overlay.data.features.filter((f) => f.properties && Math.abs(f.properties['EyeX'] - X) < 1000 && Math.abs(f.properties['EyeY'] - Y) < 1000)
        const data = getNearest(feature, e.latlng, overlay, 0.02)
        updateLayerData(data, overlay, data)
      }                
      if (!value.shiftPressed && feature.geometry && feature.geometry.type == 'Polygon' && feature.geometry.coordinates && feature.geometry.coordinates.length > 0) {
        overlay.selectedFile = e.target.feature.properties.ViewId//File
        //overlay.nearestFeatures = overlay.data.features.filter((f) => f.properties && Math.abs(f.properties['EyeX'] - X) < 200 && Math.abs(f.properties['EyeY'] - Y) < 200)          
        overlay.nearestFeatures = getNearest(feature, e.latlng, overlay)
        value.imageCompareVisible = true
      }        
      break
    case 'imageTakePositions': 
      if (e.target.feature.properties.Kind !== 'aerial') {
        return
      }
      value.filterLayer = layerKey
      overlay.selectedFile = e.target.feature.properties.ViewId
      overlay.nearestFeatures = getNearestPoint(feature, e.latlng, overlay, 0.02)
      value.imageCompareVisible = true      
      break    
    }
  }

  function contextmenu (e) {        
    const layer = e.target   
    const feature = layer?.feature
    const multiPoint = feature.geometry.type === 'MultiPoint' && feature?.properties?.EyeX
    const info = getInfobyFeature(key,feature,multiPoint)
    layerProperties.menuItems = info.menuItems
    layerProperties.menuTabs = info.menuTabs
    layerProperties.title = info.title
  }

  function empty (e) {

  }

  function onEachFeature (feature, layer) {
    const s = value.overlays[key].style
    if (s && (s.click || s.highlight)) {
      layer.on({
        mouseover: s.highlight ? highlightFeature : empty,
        mouseout: s.highlight ? resetHighlight : empty,
        click: s.click ? click : empty,
        contextmenu: s.click ? contextmenu : empty
      })
    }  
  }
}

const updateLayerData = (data, overlay, value) => {
  if (overlay.tileLayer) {
    value.loader = true
    setTimeout(() => {
      const tileLayer = overlay.tileLayer
      tileLayer.clearLayers()
      tileLayer.options.filter = (feature) => {
        return true
      }
      tileLayer.addData(data)
      value.loader = false}
    , 200)
  } 
}