<template>
  <div v-if="loading"><ProgressSpinner /></div>

  <div v-else-if="error">Error: {{ error.message }}</div>

  <div v-else-if="!loading && !error">
    <form @submit.prevent="handleSubmit(!v$.$invalid)" class="grid p-fluid">
      <div class="col-12 md:col-6">
        <div class="field">
            <div class="p-float-label">
                <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted}" />
                <label for="name" :class="{'p-error':v$.name.$invalid && submitted}">Name*</label>
            </div>
            <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
        </div>
        <div class="field">
            <div class="p-float-label">
                <InputText id="surname" v-model="v$.surname.$model" :class="{'p-invalid':v$.surname.$invalid && submitted}" />
                <label for="surname" :class="{'p-error':v$.surname.$invalid && submitted}">Surname*</label>
            </div>
            <small v-if="(v$.surname.$invalid && submitted) || v$.surname.$pending.$response" class="p-error">{{v$.surname.required.$message.replace('Value', 'Surname')}}</small>
        </div>
        <div class="field">
            <div class="p-float-label p-input-icon-right">
                <i class="pi pi-envelope" />
                <InputText id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
            </div>
            <span v-if="v$.email.$error && submitted">
                <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{error.$message}}</small>
                </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
        </div>
        <div class="field">
            <div class="p-float-label">
                <Dropdown id="roleAlias" v-model="v$.roleAlias.$model" :options="roles" optionLabel="name" optionValue="alias"/>
                <label for="roleAlias">Role</label>
            </div>
        </div>
        <div class="field field-input-switch">
            <InputSwitch id="flagActive" name="flagActive" :value="true" v-model="v$.flagActive.$model" :class="{'p-invalid':v$.flagActive.$invalid && submitted}" />
            <label for="flagActive" :class="{'p-error': v$.flagActive.$invalid && submitted}">active user</label>
        </div>
        <div class="flex flex-row flex-wrap justify-content-end">
          <Button label="Save"  type="submit" icon="fa-solid fa-check" class="p-button-success mr-2 mb-2 mlwn-button-submit"></Button>
          <router-link :to="{ name: 'user-list' }">
            <Button label="Cancel" icon="fa-solid fa-xmark" class="p-button-secondary mr-2 mb-2" />
          </router-link>  
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, ref, watchEffect, computed } from 'vue'
import { email, required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { useQuery, useMutation } from '@vue/apollo-composable'

import { GET_USER_WITH_ROLES } from '@/apollo/queries/user'
import { UPDATE_USER } from '@/apollo/mutations/user'
import { useToast } from "primevue/usetoast"


export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const toast = useToast()

    const { result, loading, error } = useQuery(
      GET_USER_WITH_ROLES, 
      { userId: props.userId }, {
        errorPolicy: 'all',
      })

    const { mutate: updateUser } = useMutation(UPDATE_USER)
    
    const user = computed(() => result.value?.getUser)
    const roles = computed(() => result.value?.getRoles)
    
    const state = reactive({
      id: null,
      name: null,
      surname: null,
      email: null,
      flagActive: null,
      roleAlias: null,
      role: {
        alias: null,
        name: null
      }
    })
    watchEffect(() => {
      if(result && result.value) {
        //
        for(const key of Object.keys(result.value.getUser)){
          state[key] = result.value.getUser[key]
        }
      }
    })
    
    const rules = {
      id: { required },
      name: { required },
      surname: { required },
      email: { required, email },
      flagActive: { required },
      roleAlias: { required },
    }

    const submitted = ref(false)
    const v$ = useVuelidate(rules, state)
    const handleSubmit = async (isFormValid) => {
      submitted.value = true

      if (!isFormValid) {
        return
      }

      try {
        let res = await updateUser({
          id: parseInt(state.id),
          name: state.name,
          surname: state.surname,
          email: state.email,
          flagActive: state.flagActive,
          roleAlias: state.roleAlias
        })
        
        if(res.data.updateUser){
          toast.add({severity:'success', summary: 'Success', detail:'Succesfully updated', life: 3000})
        } else {
          toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
        }
      } catch (err) {
        toast.add({ severity: 'error', summary: 'Error', detail: 'Error occured while saving', life: 3000 })
        console.error(err)
      }

      
    }


    return { 
      user,
      state, 
      roles,
      v$, 
      handleSubmit, 
      submitted, 
      loading, 
      error
    }
  },
  components: {

  },
}

</script>

<style lang="scss" scoped>
.field {
    margin-top: 2rem;
}

.mlwn-button-submit {
  width: auto;
}
</style>