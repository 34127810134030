<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>
          {{ storage?.name }}
        </template>
        <template #content>


          <div class="flex">
            <div class="w-6">
              <attributes-list :item="storage" :attributes="renderAttributes">
              </attributes-list>
            </div>
            <div class="w-4">
              <div class="input-chart-wrapper">
                <!--<Chart type="pie" :data="chartData" :options="lightOptions" />-->
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>
          <div class="flex justify-content-between flex-wrap">
              <div class="flex align-items-center justify-content-center">Volumes</div>
              <div class="flex align-items-center justify-content-center">
                <router-link :to="{
                  name: 'storage-volume-register',
                  props: {
                    storageId
                  }
                }" v-tooltip.bottom="'Register new volume'">
                  <Button data-cy="volumesList__registerNewVolume" type="button" icon="fa-solid fa-plus"></Button>
                </router-link>
              </div>
          </div>
        </template>
        <template #content>
          <volumes-table v-if="!reloadTable" v-bind:storageId="storageId"></volumes-table>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import VolumesTable from './components/VolumesTable.vue'
import { computed, watch, ref, nextTick } from 'vue'
import AttributesList from '@/components/UI/AttributesList.vue'
import { useStorages } from '@/composables/useStorages'
import { ByteSize, useStrings } from '@/composables/helpers/useStrings'

const props = defineProps({
  storageId: {
    type: String,
    required: true
  }
})

const cStorageId = computed(() => props.storageId)
const reloadTable = ref(false)

const { getStorage } = useStorages()
const { storage, refetch } = getStorage(props.storageId)
const { formatByteSize } = useStrings()


watch(cStorageId, (newValue) => {
  reloadTable.value = true
  refetch(newValue)
  nextTick(() => {
    reloadTable.value = false
  })
})



const renderAttributes = ref({
  id: { label: 'id',  value: (item) => item?.id },
  name:  { label: 'name',  value: (item) => item?.name },
  sizeQuotaGB: { label: 'size quota', value: (item) => formatByteSize(item?.sizeQuotaMB, ByteSize.MB, ByteSize.GB) + ' GB'}
})
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {

      .p-datatable-thead>tr>th,
      .p-datatable-tfoot>tr>td {
        display: none !important;
      }

      .p-datatable-tbody>tr {
        border-bottom: 1px solid #dee2e6;

        >td {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
