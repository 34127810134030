import L from 'leaflet'

L.SVG.Tile = L.SVG.extend({

  initialize: function (tileCoord, tileSize, options) {
    L.SVG.prototype.initialize.call(this, options)
    this._tileCoord = tileCoord
    this._size = tileSize

    this._initContainer()
    this._container.setAttribute('width', this._size.x)
    this._container.setAttribute('height', this._size.y)
    this._container.setAttribute('viewBox', [0, 0, this._size.x, this._size.y].join(' '))

    this._layers = {}
  },

  getCoord: function() {
    return this._tileCoord
  },

  getContainer: function() {
    return this._container
  },

  onAdd: L.Util.falseFn,

  addTo: function(map) {
    this._map = map
    if (this.options.interactive) {
      for (var i in this._layers) {
        var layer = this._layers[i]
        // By default, Leaflet tiles do not have pointer events.
        layer._path.style.pointerEvents = 'auto'
        this._map._targets[L.stamp(layer._path)] = layer
      }
    }
  },

  removeFrom: function (map) {
    if (this.options.interactive) {
      for (var i in this._layers) {
        var layer = this._layers[i]
        delete this._map._targets[L.stamp(layer._path)]
      }
    }
    delete this._map
  },

  _initContainer: function() {
    L.SVG.prototype._initContainer.call(this)
    var rect =  L.SVG.create('rect')
  },

  /// TODO: Modify _initPath to include an extra parameter, a group name
  /// to order symbolizers by z-index

  _addPath: function (layer) {
    this._rootGroup.appendChild(layer._path)
    this._layers[L.stamp(layer)] = layer
  },

  _updateIcon: function (layer) {
    var path = layer._path = L.SVG.create('image'),
      icon = layer.options.icon,
      options = icon.options,
      size = L.point(options.iconSize),
      anchor = options.iconAnchor ||
               size && size.divideBy(2, true),
      p = layer._point.subtract(anchor)
    path.setAttribute('x', p.x)
    path.setAttribute('y', p.y)
    path.setAttribute('width', size.x + 'px')
    path.setAttribute('height', size.y + 'px')
    path.setAttribute('href', options.iconUrl)
  }
})


L.svg.tile = function(tileCoord, tileSize, opts){
  return new L.SVG.Tile(tileCoord, tileSize, opts)
}

