<template>
  <div class="grid">
		<div class="col-12">
			<Card>
				<template #title>
					Targets
				</template>
				<template #content>
					- to be imported from honeydew 1.x
				</template>
      </Card>
		</div>
	</div>
</template>

<script setup lang="ts">
</script>
