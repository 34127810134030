<template>
	<div class="p-grid">
		<div class="p-col-12">
			<Card>
				<template #title>	
					Honeydew
				</template>
				<template #content>
					<p>
						Honeydew is a service wrapper around Vadstena. It exposes the whole Vadstena functionality as (GraphQL) API and in web-based UI. It also adds much of related functionality like data management, resource management and ingest or export process.
					</p>
				</template>
			</Card>
		</div>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
</style>
