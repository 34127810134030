import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { useAuth } from "@/composables/useAuth"

const router = createRouter({
  history: createWebHistory(),
  routes,
})


router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login', '/login/account-not-activated']
  const authRequired = !publicPages.includes(to.path)
  const { user } = useAuth()

  if (authRequired) {
    if (user.value) {
      if (user.value.flagActive === true) {
        next()
      } else {
        next({ name: 'account-not-activated' })
      }
    } else {
      next({ name: 'login' })
    }
  } else {
    next()
  }
})

export default router
