<template>
  <div class="grid">
		<div class="col-12">
			<Card>
				<template #title>
					Users
				</template>
				<template #content>
					<users-table></users-table>
				</template>
      </Card>
		</div>
	</div>
</template>

<script>
import UsersTable from './components/UsersTable.vue'

export default {
  setup() {
  },
  components: {
    UsersTable
  }
}
</script>
