<template>
  <div class="grid">
		<div class="col-12">
			<Card>
				<template #title>
					Notification Center
				</template>
				<template #content>
          - to be implemented <strong></strong>
				</template>
			</Card>
		</div>
	</div>
</template>

<script setup lang="ts">
</script>
