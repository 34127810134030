<template>
    <div class="layout-topbar">
        <div class="layout-topbar-wrapper">
            <div class="layout-topbar-left">
                <router-link to="/">
                    <div class="layout-topbar-logo" id="logolink" style="cursor: pointer; outline: none">
                        <img id="app-logo" alt="poseidon-layout" :class="(topbarTheme === 'light' ? 'ligth' : 'dark')" src="@/assets/vadstena-logo.png" />
                    </div>
                </router-link>
            </div>

            <div class="layout-topbar-right">
                <a class="menu-button" href="#" @click="$emit('menu-button-click', $event)">
                    <i class="pi pi-bars"></i>
                </a>

                <ul class="layout-topbar-actions">

                    <li class="topbar-item user-profile" :class="{ 'active-topmenuitem': activeTopbarItem === 'profile' }">
                        <a href="#" @click="onTopbarItemClick($event, 'profile')">
                          <div class="profile-icon">
                            <i class="fa-solid fa-user user-icon"></i>
                          </div>
                          
                            <div class="profile-info navbar">
                                <h6>{{ user?.displayName }}</h6>
                                <!--<span>Webmaster</span>-->
                            </div>
                        </a>

                        <ul class="fadeInDown">
                            <li class="layout-submenu-header">
                              <i class="fa-solid fa-user user-icon"></i>
                                <div class="profile-info">
                                    <h6>{{ user?.displayName }}</h6>
                                    <!--<span>Webmaster</span>-->
                                </div>
                            </li>
                            <!--<li role="menuitem">
                                <a href="#" @click="onTopbarSubItemClick($event)">
                                    <i class="pi pi-cog"></i>
                                    <h6>Settings</h6>
                                </a>
                            </li>-->
                            <li role="menuitem">
                                <a href="#" @click="logout">
                                    <i class="pi pi-power-off"></i>
                                    <h6>Logout</h6>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <!--
                <a class="layout-rightpanel-button" href="#" @click="$emit('right-panel-click', $event)">
                  <i class="pi pi-arrow-left"></i>
                </a>
                -->
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useAuth } from '@/composables/useAuth'

export default defineComponent({
  name: 'AppTopbar',
  emits: ['menu-button-click', 'right-panel-click', 'topbar-item-click', 'search-click'],
  props: {
    topbarTheme: String,
    activeTopbarItem: String,
  },
  setup() {
    const { logout, user } = useAuth()
  
    return {
      user,
      logout
    }
  },
  methods: {
    onTopbarItemClick(event, item) {
      this.$emit('topbar-item-click', { originalEvent: event, item: item });
      event.preventDefault();
    },
    onTopbarSubItemClick(event) {
      event.preventDefault();
    },
  },
});
</script>
<style lang="scss" scoped>
#app-logo {
  height: 56px;
  margin-top: 5px;
  &.dark {
    filter: invert(1);
  }
}

.layout-submenu-header {
  .user-icon {
    background-color: white;
    border-radius: 50%;
    padding: 7px 8px;
  }
}

.user-icon {
    margin-top: 1px;
}

@media screen and (max-width: 992px) {
  .user-icon {
    background-color: white;
    border-radius: 50%;
    padding: 7px 8px;
  }
}

@media screen and (min-width: 993px) {
  .profile-info.navbar {
    display: flex;
    h6 {
      align-self: center;
    }
  }
}
</style>
