/* eslint-disable vue/no-reserved-component-names */
import "@fortawesome/fontawesome-free/css/all.min.css";

import { createApp, reactive, h } from "vue";

import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import Galleria from "primevue/galleria";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import Tag from "primevue/tag";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Timeline from "primevue/timeline";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import Tree from "primevue/tree";
import TreeSelect from "primevue/treeselect";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";

import BlockViewer from "@/components/BlockViewer.vue";
import CodeHighlight from "./helpers/AppCodeHighlight";

import AppVue from "./App.vue";
import router from "./router/index";

import {
  DefaultApolloClient,
  provideApolloClient,
} from "@vue/apollo-composable";
import { initApolloClient } from "./apollo/ApolloClient";

import { msalPlugin } from "./auth/plugins/msalPlugin";
import { getMsalInstance } from "./auth/authConfig";

import simpleAcl from "@/acl/index";

// Vue MD editor
// import VMdEditor from "@kangc/v-md-editor";
// import "@kangc/v-md-editor/lib/style/base-editor.css";
// import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
// import "@kangc/v-md-editor/lib/theme/style/vuepress.css";

// MD Editor
import { MdEditor, MdPreview } from "md-editor-v3";
import "md-editor-v3/lib/style.css";

// Prism
// import Prism from "prismjs";
// // highlight code
// import "prismjs/components/prism-json";
// import enUS from "@kangc/v-md-editor/lib/lang/en-US";

// VMdEditor.lang.use("en-US", enUS);
// VMdEditor.use(vuepressTheme, {
//   Prism,
// });

import "@/assets/styles.scss";

import { defineRule } from "vee-validate";
import AllRules from "@vee-validate/rules";

Object.keys(AllRules).forEach((rule) => {
  defineRule(rule, AllRules[rule]);
});

export const initalizeApp = (config: any): any => {
  // location reload
  window.addEventListener(
    "storage",
    function (event) {
      if (event.key == "event-logout") {
        this.window.location.reload();
      }
      if (event.key == "event-login") {
        this.window.location.reload();
      }
    },
    false
  );

  // create App
  const app = createApp({
    setup() {
      const apolloClient = initApolloClient(config.API_URL);

      provideApolloClient(apolloClient);

      app.provide(DefaultApolloClient, apolloClient);
    },

    render: () => h(AppVue),
  });

  const msalInstance = getMsalInstance(
    config.MICROSOFT_CLIENT_ID,
    config.MICROSOFT_AUTHORITY,
    window.location.origin + "/login"
  );

  app.use(msalPlugin, msalInstance);
  app.config.globalProperties.$appState = reactive({
    isRTL: false,
    isNewThemeLoaded: false,
    layoutMode: "dim",
  });
  app.use(PrimeVue, { ripple: true });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(router);
  app.use(simpleAcl);

  app.directive("tooltip", Tooltip);
  app.directive("ripple", Ripple);
  app.directive("code", CodeHighlight);
  app.directive("badge", BadgeDirective);
  app.directive("styleclass", StyleClass);

  app.component("Accordion", Accordion);
  app.component("AccordionTab", AccordionTab);
  app.component("AutoComplete", AutoComplete);
  app.component("Avatar", Avatar);
  app.component("AvatarGroup", AvatarGroup);
  app.component("Badge", Badge);
  app.component("Breadcrumb", Breadcrumb);
  app.component("Button", Button);
  app.component("Calendar", Calendar);
  app.component("Card", Card);
  app.component("Carousel", Carousel);
  app.component("Chart", Chart);
  app.component("Checkbox", Checkbox);
  app.component("Chip", Chip);
  app.component("Chips", Chips);
  app.component("ColorPicker", ColorPicker);
  app.component("Column", Column);
  app.component("ConfirmDialog", ConfirmDialog);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("ContextMenu", ContextMenu);
  app.component("DataTable", DataTable);
  app.component("DataView", DataView);
  app.component("DataViewLayoutOptions", DataViewLayoutOptions);
  app.component("Dialog", Dialog);
  app.component("Divider", Divider);
  app.component("Dropdown", Dropdown);
  app.component("Fieldset", Fieldset);
  app.component("FileUpload", FileUpload);
  app.component("Galleria", Galleria);
  app.component("Image", Image);
  app.component("InlineMessage", InlineMessage);
  app.component("Inplace", Inplace);
  app.component("InputMask", InputMask);
  app.component("InputNumber", InputNumber);
  app.component("InputSwitch", InputSwitch);
  app.component("InputText", InputText);
  app.component("Knob", Knob);
  app.component("Listbox", Listbox);
  app.component("MegaMenu", MegaMenu);
  app.component("Menu", Menu);
  app.component("Menubar", Menubar);
  app.component("Message", Message);
  app.component("MultiSelect", MultiSelect);
  app.component("OrderList", OrderList);
  app.component("OrganizationChart", OrganizationChart);
  app.component("OverlayPanel", OverlayPanel);
  app.component("Paginator", Paginator);
  app.component("Panel", Panel);
  app.component("PanelMenu", PanelMenu);
  app.component("Password", Password);
  app.component("PickList", PickList);
  app.component("ProgressBar", ProgressBar);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("RadioButton", RadioButton);
  app.component("Rating", Rating);
  app.component("SelectButton", SelectButton);
  app.component("ScrollPanel", ScrollPanel);
  app.component("ScrollTop", ScrollTop);
  app.component("Slider", Slider);
  app.component("Sidebar", Sidebar);
  app.component("Skeleton", Skeleton);
  app.component("SpeedDial", SpeedDial);
  app.component("SplitButton", SplitButton);
  app.component("Splitter", Splitter);
  app.component("SplitterPanel", SplitterPanel);
  app.component("Steps", Steps);
  app.component("TabMenu", TabMenu);
  app.component("TabView", TabView);
  app.component("TabPanel", TabPanel);
  app.component("Tag", Tag);
  app.component("Textarea", Textarea);
  app.component("TieredMenu", TieredMenu);
  app.component("Timeline", Timeline);
  app.component("Toast", Toast);
  app.component("Toolbar", Toolbar);
  app.component("ToggleButton", ToggleButton);
  app.component("Tree", Tree);
  app.component("TreeSelect", TreeSelect);
  app.component("TreeTable", TreeTable);
  app.component("TriStateCheckbox", TriStateCheckbox);

  app.component("BlockViewer", BlockViewer);
  app.component("Editor", Editor);

  // app.use(VMdEditor);
  app.use(MdEditor);
  app.use(MdPreview);

  app.mount("#app");

  return app;
};
