import { gql } from 'graphql-tag'

export const GET_PRESETS = gql`
  query GET_PRESETS($filter: InputFilter) {
    getPresets(filter: $filter) {
      presets {
        key
        value
        presetType {
          alias
          name
        }
      }
      pageInfo {
        limit
        offset
        total
      }
      timestamp
    }
  }
`

export const GET_PRESET = gql`
  query GET_PRESET($key: ID!) {
    getPreset(key: $key) {
      key
      value
      presetType {
        alias
        name
      }
    }
    getPresetTypes {
      alias
      name
    }
  }
`

export const GET_PRESET_TYPES = gql`
  query GET_PRESET_TYPES {
    getPresetTypes {
      alias
      name
    }
  }
`

export const GET_PRESETS_BY_TYPE = gql`
  query GET_PRESETS_BY_TYPE ($presetTypeAlias: String!) {
    getPresetsByType(presetTypeAlias: $presetTypeAlias) {
      key
      presetType {
        alias
        name
      }
      value
    }
  }
`
