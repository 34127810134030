import { gql } from "@/gql"


export const REGISTER_VOLUME = gql(`
  mutation RegisterVolume($input: VolumeCreateInput!) {
    registerVolume(input: $input) {
      name
      remotePath
      mountAlias
      sizeReservedMB
      sizeOccupiedMB
      volumeStatus {
        alias
      }
      storage {
        id
      }
    }
  }
`)

export const UNREGISTER_REGISTERED_VOLUME = gql(`
 mutation UNREGISTER_REGISTERED_VOLUME($mountAlias: String!) {
  unregisterRegisteredVolume(mountAlias: $mountAlias) {         
    name
    remotePath
    mountAlias
    createdAt
    updatedAt
    sizeReservedMB
    sizeOccupiedMB
    volumeStatus {
      alias
    }
    storage {
      id
      sizeQuotaMB
      supportsLinks
      type
    }
  }
}
`)

export const REGISTER_UNREGISTERED_VOLUME = gql(`
 mutation REGISTER_UNREGISTERED_VOLUME($mountAlias: String!) {
  registerUnregisteredVolume(mountAlias: $mountAlias) {    
    name
    remotePath
    mountAlias
    createdAt
    updatedAt
    sizeReservedMB
    sizeOccupiedMB
    volumeStatus {
      alias
    }
    storage {
      id
      sizeQuotaMB
      supportsLinks
      type
    }
  }
}
`)

export const UPDATE_VOLUME = gql(`
  mutation UpdateVolume($input: VolumeUpdateInput!) {
    updateVolume(input: $input) {
      name
      remotePath
      mountAlias
      sizeOccupiedMB
      sizeReservedMB
      createdAt
      updatedAt
      storage {
        id
      }
  }
}
`)

