import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser'

export const getMsalInstance = (clientId: string, authority: string, redirectUri: string) => { 
  const msalConfig: Configuration = {
    auth: {
      clientId,
      authority,
      redirectUri,
    },
    cache: {
      cacheLocation: 'sessionStorage'
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {	
            return	
          }
          switch (level) {	
          case LogLevel.Error:	
            console.error(message)	
            return	
          case LogLevel.Info:	
            console.info(message)	
            return	
          case LogLevel.Verbose:	
            console.debug(message)	
            return	
          case LogLevel.Warning:	
            console.warn(message)	
            return	
          default:
            return
          }
        },
        logLevel: LogLevel.Verbose
      }
    }
  }
  
  return new PublicClientApplication(msalConfig)
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
}

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
}
