import { ComputedRef, computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { Collocation }  from '@/gql/graphql'
import { GET_COLLOCATIONS } from '@/apollo/queries/data_mgmt'

export function useCollocations() {
  /**
   * @description get collocations
   * @return {*}  
   */
  function getCollocations() {
    const { result, error, loading } = useQuery(GET_COLLOCATIONS, {
    }, {
      errorPolicy: 'all'
    })

    const collocations : ComputedRef<Collocation[]> = computed(() => result?.value?.getCollocations ?? [])

    return { collocations, error, loading }
  }
 
  return { getCollocations }
}
