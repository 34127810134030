import UserList from '@/components/Admin/Settings/User/UserList.vue'
import UserDetail from '@/components/Admin/Settings/User/UserDetail.vue'
import Presets from '@/components/Admin/Settings/Presets/Presets.vue'
import PresetDetail from '@/components/Admin/Settings/Presets/PresetDetail.vue'
import PresetCreate from '@/components/Admin/Settings/Presets/PresetCreate.vue'
import Configuration from '@/components/Admin/Settings/Configuration/Configuration.vue'
import About from '@/components/Admin/Settings/About/About.vue'
import Permissions from '@/components/Admin/Settings/Permissions/Permissions.vue'
import Collocations from '@/components/Admin/Settings/Collocations/Collocations.vue'
import ServiceTasks from '@/components/Admin/Settings/ServiceTasks/ServiceTasks.vue'
import ServiceTaskCreate from '@/components/Admin/Settings/ServiceTasks/ServiceTaskCreate.vue'
import SegmentationClasses from '@/components/Admin/Settings/SegmentationClasses/SegmentationClasses.vue'
import SemanticFeatures from '@/components/Admin/Settings/SemanticFeatures/SemanticFeatures.vue'
import Storages from '@/components/Admin/Settings/Storages/Storages.vue'
import NotificationCenter from '@/components/Admin/Settings/NotificationCenter/NotificationCenter.vue'
import ClusterList from '@/components/Admin/Settings/Cluster/ClusterList.vue'
import ClusterCreate from '@/components/Admin/Settings/Cluster/ClusterCreate.vue'
import Appearence from '@/components/Admin/Settings/Appearance/Appearence.vue'
import { RouteRecordRaw } from 'vue-router'


export const settingRoutes: RouteRecordRaw[] = [
  {
    path: 'settings/cluster',
    name: 'cluster-list',
    component: ClusterList,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Clusters', name: 'cluster-list' },
      ]      
    }
  },
  {
    path: 'settings/cluster/create',
    name: 'cluster-create',
    component: ClusterCreate,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Clusters', name: 'cluster-list' },
        { label: 'Cluster create', name: 'cluster-create' },
      ]      
    }
  },
  {
    path: 'settings/user',
    name: 'user-list',
    component: UserList,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Users', name: 'user-list' },
      ]
    }
  },
  {
    path: 'settings/user/:userId',
    name: 'user-detail',
    component: UserDetail,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Users', name: 'user-list' },
        { label: 'User detail' },
      ]
    },
    props: true
  },
  {
    path: 'settings/about',
    name: 'about',
    component: About,
    meta: {
      can: 'is-admin',
      breadcrumb: [{ label: 'About', name: 'about' }]
    },
  },
  {
    path: 'settings/presets',
    name: 'presets',
    component: Presets,
    meta: {
      can: 'is-admin',
      breadcrumb: [{ label: 'Presets' }]
    }
  },
  {
    path: 'settings/presets/create',
    name: 'preset-create',
    component: PresetCreate,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Presets', name: 'presets' },
        { label: 'Create preset' }
      ]
    },
    props: true
  },
  {
    path: 'settings/presets/:presetKey',
    name: 'preset-detail',
    component: PresetDetail,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Presets', name: 'presets' },
        { label: 'Preset detail' }
      ]
    },
    props: true
  },
  {
    path: 'settings/configuration',
    name: 'configuration',
    component: Configuration,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/appearence',
    name: 'appearence',
    component: Appearence,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/permissions',
    name: 'permissions',
    component: Permissions,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/collocations',
    name: 'collocations',
    component: Collocations,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/service-tasks',
    name: 'service-tasks',
    component: ServiceTasks,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Service tasks' }
      ]
    }
  },
  {
    path: 'settings/service-tasks-create',
    name: 'service-tasks-create',
    component: ServiceTaskCreate,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Service tasks', name: 'service-tasks' },
        { label: 'Create service task' }
      ]
    }
  },
  {
    path: 'settings/segmenation-classes',
    name: 'segmentation-classes',
    component: SegmentationClasses,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/semantic-features',
    name: 'semantic-features',
    component: SemanticFeatures,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/storages',
    name: 'storages',
    component: Storages,
    meta: {
      can: 'is-admin'
    }
  },
  {
    path: 'settings/notification-center',
    name: 'notification-center',
    component: NotificationCenter,
    meta: {
      can: 'is-admin'
    }
  },
]