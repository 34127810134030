import { gql } from "@/gql";

export const GetClusters = gql(`
query GetClusters($limit: Int, $offset: Int, $sort: InputSort, $filter: InputFilter) {
    getClusters(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
      clusters {
        id
        name
        error
        workdirDmMountAlias
        status {
          alias
          name
          description
        }
        requestToStop
        provisioningDmTaskId
        logVolumeDmMountAlias
        dmCollocationId
        cleanupDmTaskId
        serviceDmTaskId
        scenes {
          id
          name
          notes
        }
      }
      pageInfo {
        limit
        offset
        total
      }
    }
  }
`)


export const SpawnClusterService = gql(`
mutation SpawnClusterService($id: String!, $dmCollocationId: String!, $workdirDmMountAlias: String!, $logVolumeDmMountAlias: String!) {
  spawnClusterService(id: $id, dmCollocationId: $dmCollocationId, workdirDmMountAlias: $workdirDmMountAlias, logVolumeDmMountAlias: $logVolumeDmMountAlias) {
    id
    name
    error
    workdirDmMountAlias
    status {
      alias
      name
      description
    }
    requestToStop
    provisioningDmTaskId
    logVolumeDmMountAlias
    dmCollocationId
    cleanupDmTaskId
    serviceDmTaskId
  }
}
`)

export const StopClusterService = gql(`
mutation StopClusterService($id: String!) {
  stopClusterService(id: $id) {
    id
    name
    error
    workdirDmMountAlias
    status {
      alias
      name
      description
    }
    requestToStop
    provisioningDmTaskId
    logVolumeDmMountAlias
    dmCollocationId
    cleanupDmTaskId
    serviceDmTaskId
  }
}
`)