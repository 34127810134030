import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { GET_DM_TASK_TYPES } from '@/apollo/queries/data_mgmt'
import { Get_Dm_Task_TypesQuery } from '@/gql/graphql'

export function useTaskTypes() {
  /**
   * @description Gets all DM Task Types
   * @return {*}  
   */
  function getTaskTypes() {
    const { result, error, loading } = useQuery(GET_DM_TASK_TYPES, {
    }, {
      errorPolicy: 'all'
    })

    const taskTypes = computed(() => {
      return (result?.value as Get_Dm_Task_TypesQuery)?.getDmTaskTypes ?? []
    })

    return { taskTypes, error, loading }
  }

  return { getTaskTypes }
}
