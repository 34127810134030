import { ref, Ref} from 'vue'

/**
 * @description Required configuration of app
 * @interface AppConfig
 */
interface ThemeConfig {
  theme?: "denim"
  topbarTheme?: "light"
  
  inputStyle?: "outlined",
  isRTL?: false
  layoutMode?: "light"
  menuMode?: "static"
  menuTheme?: "dark"
  ripple?: false

  [key: string]: number | string | boolean | undefined
}

/**
 * @description composables that works with app configuration
 * @export
 * @return {*}  
 */
export function useTheme() {
  const lsConfigKey = "_hndw_theme"
  const config: Ref<ThemeConfig|null> = ref(null)

  /**
   * @description loads App configuration
   * @return {*}  {(Promise<AppConfig | null>)}
   */
  const getTheme = () : ThemeConfig => {
    const _theme = window.localStorage.getItem(lsConfigKey)
    let theme: ThemeConfig = {
      theme: "denim",
      topbarTheme: "light",
      inputStyle: "outlined",
      isRTL: false,
      layoutMode: "light",
      menuMode: "static",
      menuTheme: "dark",
      ripple: false
    }
    if (_theme && typeof _theme === 'string') {
      theme = Object.assign(theme, JSON.parse(_theme))
    }
    return theme
  }

  const persistThemeAttribute = (key: string, value: string | boolean) => {
    const theme = getTheme()

    theme[key] = value

    persistConfig(theme)
  }

  /**
   * @description set persistance of configuration to localstorage in case of delete window.Honeydew.AppConfig
   * @param {AppConfig} config
   */
  const persistConfig = (config: ThemeConfig) => {
    window.localStorage.setItem(lsConfigKey, JSON.stringify(config))
  }

  const resetConfig = () => {
    window.localStorage.removeItem(lsConfigKey)
  }

  return { config, getTheme, resetConfig, persistThemeAttribute}
}