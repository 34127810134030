export const SIDEBAR_LINK_TYPES = {
  DATA_VOLUMES: "data-volumes-submenu",
};

export default [
  // {
  //   label: 'Dashboard',
  //   icon: 'fa-solid fa-home',
  //   to: {
  //     name: 'dashboard'
  //   },
  //   role: 'is-annotator',
  //   items: [
  //     {
  //       label: 'Dashboard',
  //       icon: 'fa-solid fa-home',
  //       to: {
  //         name: 'dashboard'
  //       },
  //       role: 'is-admin'
  //     }
  //   ]
  // },
  {
    label: "Processing",
    icon: "fa-solid fa-clipboard-check",
    role: "is-admin",
    items: [
      /*
      {
        label: 'Targets',
        icon: 'fa-solid fa-images',
        to: {
          name: 'target-list'
        },
        role: 'is-admin'
      },
      */
      {
        label: "All scenes",
        icon: "fa-solid fa-map",
        to: {
          name: "scene-list",
        },
        role: "is-admin",
      },
      {
        label: "Projects",
        icon: "fa-solid fa-folder-open",
        to: {
          name: "project-list",
        },
        role: "is-admin",
      },
      /*
      {
        label: 'Clusters',
        icon: 'fa-solid fa-laptop-house',
        to: {
          name: 'cluster-list'
        },
        role: 'is-admin'
      },
      */
    ],
  },
  {
    label: "Storages",
    icon: "fa-solid fa-server",
    role: "is-admin",
    type: SIDEBAR_LINK_TYPES.DATA_VOLUMES, //this key will download data volumes from DATA MGMT
    items: [],
  },
  /*
  {
    label: 'Quality control',
    icon: 'fa-solid fa-medal',
    role: 'is-admin',
    items: [
      {
        label: 'Mesh QC',
        icon: 'fa-solid fa-cubes',
        to: {
          name: 'quality-control-mesh'
        },
        role: 'is-admin'
      },
      {
        label: 'Features QC',
        icon: 'fa-solid fa-tree-city',
        to: {
          name: 'quality-control-semantic-features'
        },
        role: 'is-admin'
      },
    ]
  },
  */
  {
    label: "Settings",
    icon: "fa-solid fa-cog",
    role: "is-admin",
    items: [
      {
        label: "Users",
        icon: "fa-solid fa-users",
        to: {
          name: "user-list",
        },
        role: "is-admin",
      },
      /*
      {
        label: 'Permissions',
        icon: 'fa-solid fa-lock',
        to: {
          name: 'permissions'
        },
        role: 'is-admin'
      },
      */
      {
        label: "Presets",
        icon: "fa-solid fa-save",
        to: {
          name: "presets",
        },
        role: "is-admin",
      },
      /*
      {
        label: 'Collocations',
        icon: 'fa-solid fa-globe',
        to: {
          name: 'collocations'
        },
        role: 'is-admin'
      },
      */
      {
        label: "Service tasks",
        icon: "fa-solid fa-list",
        to: {
          name: "service-tasks",
        },
        role: "is-admin",
      },
      {
        label: "Clusters",
        icon: "fa-solid fa-server",
        to: {
          name: "cluster-list",
        },
        role: "is-admin",
      },
      /*
      {
        label: 'Segmentation classes',
        icon: 'fa-solid fa-layer-group',
        to: {
          name: 'segmentation-classes'
        },
        role: 'is-admin'
      },
      {
        label: 'Semantic features',
        icon: 'fa-solid fa-drafting-compass',
        to: {
          name: 'semantic-features'
        },
        role: 'is-admin'
      },
      {
        label: 'Storages',
        icon: 'fa-solid fa-hdd',
        to: {
          name: 'storages'
        },
        role: 'is-admin'
      },
      {
        label: 'Notification center',
        icon: 'fa-solid fa-bell',
        to: {
          name: 'notification-center'
        },
        role: 'is-admin'
      },
      {
        label: 'Appearence',
        icon: 'fa-solid fa-bell',
        to: {
          name: 'appearence'
        },
        role: 'is-admin'
      },
      */
      {
        label: "About honeydew",
        icon: "fa-solid fa-info",
        to: {
          name: "about",
        },
        role: "is-admin",
      },
    ],
  },
];
