import { ref, Ref} from 'vue'

/**
 * @description Required configuration of app
 * @interface AppConfig
 */
interface AppConfig {
  API_URL: string,
  MICROSOFT_CLIENT_ID: string,
  MICROSOFT_AUTHORITY: string,
  POLLINTERVAL?: number
}

declare global {
  interface Window {
    Honeydew: null | {
      AppConfig: AppConfig | null
    }
  }
}

/**
 * @description composables that works with app configuration
 * @export
 * @return {*}  
 */
export function useConfig() {
  const lsConfigKey = "_honeydew_config"
  const config: Ref<AppConfig|null> = ref(null)

  /**
   * @description loads App configuration
   * @return {*}  {(Promise<AppConfig | null>)}
   */
  const loadConfig = async () : Promise<AppConfig | null> => {
    try {
      if (!window.Honeydew) {
        const response = await fetch('/config.json', { cache: 'no-store' })

        const data = await response.json()
        window.Honeydew = {
          AppConfig: data as AppConfig
        }

        config.value = window.Honeydew.AppConfig
        if (config.value) {
          // reset persistent config
          resetConfig()
          persistConfig(config.value)
        }
        return window.Honeydew.AppConfig
      } else {
        return window.Honeydew.AppConfig
      }
    } catch (err) {
      console.error('error with loading configration', err)
      return null
    }
  }

  /**
   * @description set persistance of configuration to localstorage in case of delete window.Honeydew.AppConfig
   * @param {AppConfig} config
   */
  const persistConfig = (config: AppConfig) => {
    window.localStorage.setItem(lsConfigKey, JSON.stringify(config))
  }

  const resetConfig = () => {
    window.localStorage.removeItem(lsConfigKey)
  }

  /**
   * @description Function reads app config from window.Honeydew.AppConfig,
   * if not presentet, tries to load from localStorage
   * @return {*}  {(AppConfig | null)}
   */
  const getConfig = (): AppConfig | null => {
    try {
      if (!window.Honeydew) {
        console.warn('Honeydew configuration was not loaded')
        // try to load from LS
        const conf = window.localStorage.getItem(lsConfigKey)
        if (conf) {
          // config was loaded from localStorage
          window.Honeydew = {
            AppConfig: JSON.parse(conf)
          }

          return window.Honeydew.AppConfig
        } else {
          throw {
            message: "Could not load configuration"
          }
        }
  
      } else {
        return window.Honeydew.AppConfig
      }
    } catch (err) {
      console.error('error while loading config', err)
      return null
    }
  }

  return { config, loadConfig, getConfig }
}