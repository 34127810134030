import { ComputedRef, computed } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { GET_PRESETS, GET_PRESET_TYPES, GET_PRESET } from '@/apollo/queries/presets'
import { UDPATE_PRESET, CREATE_PRESET } from '@/apollo/mutations/presets'
import { PageInfo, Preset, PresetType, QueryGetPresetsArgs } from '@/gql/graphql'

/**
 * @description
 * @interface mutationResult
 */
interface presetMutationResult {
  /**
   * @description success
   * @type {boolean}
   * @memberof mutationResult
   */
  success?: boolean,
  /**
   * @description payload
   * @type {{
   *       volume: Volume
   *     }}
   * @memberof mutationResult
   */
  payload?: {
    preset: Preset
  },
  /**
   * @description error
   * @type {*}
   * @memberof mutationResult
   */
  error?: any,

  message?: string
}

export function usePresets() {
  const { mutate: createPresetMutation } = useMutation(CREATE_PRESET)
  const { mutate: updatePresetMutation } = useMutation(UDPATE_PRESET)

  /**
   * @description
   * @param {string} presetKey
   * @return {*}  
   */
  function getPreset(presetKey: string) {
    const { result, error, loading } = useQuery(GET_PRESET, {
      key: presetKey
    }, {
      errorPolicy: 'all'
    })
    const preset : ComputedRef<Preset> = computed(() => result?.value?.getPreset ?? null)
    return { preset, error, loading }
  }

  /**
   * @description
   * @return {*}  
   */
  function getPresetTypes() {
    const { result, error, loading } = useQuery(GET_PRESET_TYPES, null, {
      errorPolicy: 'all'
    })
    const presetTypes : ComputedRef<PresetType> = computed(() => result?.value?.getPresetTypes ?? null)
    return { presetTypes, error, loading }
  }

  /**
   * @description returns filtered volumes
   * @param {string} preset
   * @return {*}  
   */
  function getPresets(query: QueryGetPresetsArgs | {} = {}) {
    const { result, loading, error, refetch } = useQuery(GET_PRESETS, query)
    
    const presets: ComputedRef<Preset[]> = computed(() => result?.value?.getPresets?.presets ?? [])
    const pageInfo: ComputedRef<PageInfo> = computed(() => result?.value?.getPresets?.pageInfo ?? {})
    const timestamp: ComputedRef<string | null> = computed(() => result?.value?.getPresets?.timestamp ?? null)

    return { presets, error, loading, pageInfo, timestamp, refetch }
  }

  /**
   * @description
   * @param {Preset} preset
   * @return {*}  {Promise<presetMutationResult>}
   */
  async function createPreset(preset: Preset): Promise<presetMutationResult> {
    const result : presetMutationResult = {}
    try {
      const res = await createPresetMutation(preset)
      if(res?.data?.createPreset) {
        result.success = true
        result.payload = {
          // @ts-ignore
          preset: res?.data?.createPreset
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }

  /**
   * @description
   * @param {Preset} preset
   * @return {*}  {Promise<presetMutationResult>}
   */
  async function updatePreset(preset: Preset): Promise<presetMutationResult> {
    const result : presetMutationResult = {}
    try {
      const res = await updatePresetMutation(preset)
      if(res?.data?.updatePreset) {
        result.success = true
        result.payload = {
          // @ts-ignore
          preset: res?.data?.updatePreset
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }

  
  return { getPreset, getPresets, createPreset, updatePreset, getPresetTypes }
}
