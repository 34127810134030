import { Ref, computed } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { GetClusters, StopClusterService, SpawnClusterService } from '@/apollo/queries/cluster'
import { SpawnClusterServiceMutationVariables } from '@/gql/graphql'

/**
 * @description
 * @interface mutationResult
 */
interface ClusterMutationResult {
  /**
   * @description success
   * @type {boolean}
   * @memberof mutationResult
   */
  success?: boolean,
  /**
   * @description error
   * @type {*}
   * @memberof mutationResult
   */
  error?: any,
}

export function useClusters() {
  /**
   * @param {Ref<{
   *     limit?: number
   *     offset?: number
   *     search?: string
   *   }>} input
   * @return {*} 
   */
  function getClusters(input: Ref<{
    limit?: number
    offset?: number
    search?: string
  }>) {
    const { result, error, loading, refetch } = useQuery(GetClusters, input, {
      errorPolicy: 'all',
      pollInterval: 2000
    })
    const data = computed(() => result?.value?.getClusters.clusters ?? [])
    return { data, error, loading, refetch }
  }

  const { mutate: stopClusterServiceMutation } = useMutation(StopClusterService)
  /**
   * @param {string} alias
   * @return {*}  {Promise<ClusterMutationResult>}
   */
  async function stopClusterService(id: string): Promise<ClusterMutationResult> {
    try {
      await stopClusterServiceMutation({ id })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  const { mutate: spawnClusterService } = useMutation(SpawnClusterService)
  /**
   * @param {string} alias
   * @return {*}  {Promise<ClusterMutationResult>}
   */
  async function startClusterService(config: SpawnClusterServiceMutationVariables): Promise<ClusterMutationResult> {
    try {
      await spawnClusterService(config)
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }

  /*
  const { mutate: restartClusterServiceMutation } = useMutation(RESTART_CDN_ORIGIN_SERVICE)
  async function restartClusterService(id: number): Promise<ClusterMutationResult> {
    try {
      await restartClusterServiceMutation({ id })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }
  */

  return { getClusters, stopClusterService, startClusterService }
}
