import { gql } from 'graphql-tag'

/**
 * List of users with roles
 */
export const GET_USERS_FOR_SELECT = gql`
 query GET_USERS_FOR_SELECT {
   getUsers {
    users {
      id
      displayName
    }
   }
 }
`

/**
 * Get user, with roles
 */
export const GET_USER_WITH_ROLES = gql`
  query GET_USER_WITH_ROLES($userId: Int!) {
    getUser(id: $userId) {
      id
      name
      surname
      email
      flagActive
      roleAlias
      role {
        alias
        name
      }
    }
    getRoles {
      alias
      name
    }
  }
`

/**
 * List of users with roles
 */
export const GET_USERS_WITH_ROLES = gql`
  query GET_USERS_WITH_ROLES {
    getRoles {
      alias
      name
    }
    getUsers {
      users {
        id
        name
        surname
        email
        flagActive
        roleAlias
        role {
          alias
          name
        }
      }
      pageInfo {
        limit
        offset
        total
      }
      timestamp
    }
  }
`

/**
 * Get user, with roles
 */
export const GET_USER = gql`
  query GET_USER($userId: Int!) {
    getUser(id: $userId) {
      id
      name
      surname
      email
      flagActive
      roleAlias
      role {
        alias
        name
      }
    }
  }
`

/**
 * List of users with roles
 */
export const GET_USERS = gql`
  query GET_USERS {
    getUsers {
      users {
        id
        name
        surname
        email
        flagActive
        roleAlias
        role {
          alias
          name
        }
      }
      pageInfo {
        limit
        offset
        total
      }
      timestamp
    }
  }
`

/**
 * List of users with roles
 */
export const GET_ROLES = gql`
  query GET_ROLES {
    getRoles {
      alias
      name
    }
  }
`
