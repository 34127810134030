<template>
	<div class="grid">
		<div class="col-12">
			<Card>
				<template #title>	
					Configuration
				</template>
				<template #content>
					To be implemented
				</template>
			</Card>
		</div>
  </div>
</template>

<script setup lang="ts">
</script>
