import router from '@/router'
import { computed } from 'vue'

interface gcAuthUser {
  id: number
  roleAlias: string
  is_admin?: boolean
  is_annotator?: boolean
  permissions?: [string],
  flagActive?: boolean
}

/**
 * @description auth method
 * @export
 */
export function useAuth() {
  const user = computed(() => {
    if (window.localStorage.getItem('gc-auth-user')) {
      const usr: gcAuthUser = JSON.parse(window.localStorage.getItem('gc-auth-user') ?? '') as gcAuthUser
      if (usr) {
        usr.is_admin = (usr.roleAlias === 'Admin')
        usr.is_annotator = (usr.roleAlias === 'Annotator')
        usr.permissions = [usr.roleAlias]
      }
      return usr
    } else {
      return null
    }
  })

  const logout = () => {
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/") })
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem("event-logout", 'logout-' + Math.random())
    router.push({ name: 'login' })
  }

  const logoutWithoutDataLose = () => {
    let gcUser: gcAuthUser | string | null = window.localStorage.getItem('gc-auth-user')
    if (typeof gcUser === 'string') {
      gcUser = JSON.parse(gcUser) as gcAuthUser
      if (typeof gcUser === 'string') {
        gcUser = JSON.parse(gcUser) as gcAuthUser
      }
    }

    window.localStorage.removeItem('gc-auth-token')
    window.localStorage.removeItem('gc-auth-user')
    window.localStorage.setItem('last-user-id', gcUser ? gcUser.id.toString(0) : '')
    window.localStorage.setItem('redirect-after-login', window.location.href)
    window.localStorage.setItem('logout-type', 'soft')

    localStorage.setItem("event-logout", 'logout-' + Math.random())
    router.push({ name: 'login' })
  }

  return { logout, logoutWithoutDataLose, user }
}
