import { gql } from 'graphql-tag'

export const GET_STORAGES = gql`
 query GET_STORAGES {
  getStorages {
    id
    name
    type
    notes
    sizeQuotaMB
    supportsLinks
    volumes {
      mountAlias
      mountAlias
      mountOptions
      createdAt
      name
      remotePath
      sizeOccupiedMB
      sizeReservedMB
    }
  }
} 
`

export const GET_STORAGE = gql`
query GET_STORAGE($id: String!) {
  getStorage(id: $id) {
    id
    name
    type
    notes
    sizeQuotaMB
    supportsLinks
    volumes {
      mountAlias
      mountAlias
      mountOptions
      createdAt
      name
      remotePath
      sizeOccupiedMB
      sizeReservedMB
    }
  }
} 
`

/**
 * 
 */
export const GET_VOLUMES = gql(`
query GET_VOLUMES ($filter: InputFilter, $limit: Int, $offset: Int, $sort: InputSort) {
  getVolumes(filter: $filter, limit: $limit, offset: $offset, sort: $sort) {
    volumes {
      mountAlias
      mountOptions
      mountAlias
      name
      createdAt
      remotePath
      sizeOccupiedMB
      sizeReservedMB
      updatedAt
      storage {
        id
        name
        sizeQuotaMB
        type
        supportsLinks
        notes
      }
      volumeStatus {
        alias
        name
      }
    },
    pageInfo {
      limit
      offset
      total
    }
    timestamp
  }
}`)

export const GET_VOLUME = gql`
query GET_VOLUME($mountAlias: String!) {
  getVolume(mountAlias: $mountAlias) {
    mountAlias
    mountOptions
    mountAlias
    name
    remotePath
    sizeOccupiedMB
    sizeReservedMB
    createdAt
    updatedAt
    volumeStatus {
      alias
      name
    }
    storage {
      id
      name
    }
  }
}
`
/**
 * Get volume
 */
export const GET_DATA_BROWSER_STATUS = gql`
query GET_DATA_BROWSER_STATUS($volumeMountAlias: String!) {
  getDataBrowserStatus(volumeMountAlias: $volumeMountAlias) {
    dataBrowserServiceCreated
    dataBrowserServiceReady
    taskVolumeCreated
    taskVolumeReady
  }
}
`

/**
 * Get volume
 */
export const GET_DATA_BROWSER_LIST = gql`
query GET_DATA_BROWSER_LIST($volumeMountAlias: String!, $limit: Int, $offset: Int, $dirPath: String, $sort: DataBrowserSortInput, $search: String) {
  getDataBrowserList(volumeMountAlias: $volumeMountAlias, limit: $limit, offset: $offset, dirPath: $dirPath, sort: $sort, search: $search) {
    data {
      name
      path
      attributes {
        extension
        size
      }
      type
    }
    pageInfo {
      limit
      offset
      total
    }
  }
}
`

export const GET_VOLUME_STATUSES = gql`
query GetVolumeStatuses {
  getVolumeStatuses {
    alias
    name
  }
}
`

export const GET_DM_TASK_TYPES = gql`
query GET_DM_TASK_TYPES {
  getDmTaskTypes {
    alias
    capabilities {
      collocation {
        id
        connections {
          storage {
            id
            name
            volumes {
              mountAlias
              name
            }
          }
        }
      }
    }
  }
}
`

export const CREATE_FILE_PROVIDER_TASK = gql`
mutation CreateFileProviderTask($dmCollocationId: String!, $logDmVolumeMountAlias: String!) {
  spawnFileProviderService(dmCollocationId: $dmCollocationId, logDmVolumeMountAlias: $logDmVolumeMountAlias) {
    id
  }
}
`

// export const CREATE_IMAGE_CROPPER_TASK = gql`
// mutation CreateImageCropperServiceTask($collocationId: String!, $cdnVolumeId: Int!) {
//   createImageCropperServiceTask(collocationId: $collocationId, cdnVolumeId: $cdnVolumeId) {
//     id
//   }
// }
// `

export const CREATE_IMAGE_POSE_SERVICE_TASK = gql`
  mutation CreateImagePoseServiceTask(
    $imageSize: Int!
    $outputDmVolumeMountAlias: String!
    $wmsOphotoPath: String!
    $wmsDmVolumeMountAlias: String!
    $logDmVolumeMountAlias: String!
    $dmCollocationId: String!
  ) {
    spawnImagePoseService(
      imageSize: $imageSize
      outputDmVolumeMountAlias: $outputDmVolumeMountAlias
      wmsOphotoPath: $wmsOphotoPath
      wmsDmVolumeMountAlias: $wmsDmVolumeMountAlias
      logDmVolumeMountAlias: $logDmVolumeMountAlias
      dmCollocationId: $dmCollocationId
    ) {
      id
    }
  }
`;

// export const CANCEL_TASK = gql`
// mutation CancelTask($taskId: Int!) {
//   cancelTask(taskId: $taskId) {
//     id
//   }
// }
// `

export const GET_COLLOCATIONS = gql`
query GET_COLLOCATIONS {
  getCollocations {
    id
    name
  }
}
`