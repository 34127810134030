import { gql } from "graphql-tag";

/**
 * update user
 */
export const UPDATE_USER = gql`
  mutation updateUser(
    $id: Int!
    $name: String!
    $surname: String!
    $email: String
    $phone: String
    $flagActive: Boolean
    $attributes: UserAttributes
    $roleAlias: Roles!
  ) {
    updateUser(
      id: $id
      name: $name
      surname: $surname
      email: $email
      phone: $phone
      flagActive: $flagActive
      attributes: $attributes
      roleAlias: $roleAlias
    ) {
      id
    }
  }
`;
