import { gql } from "@/gql";

export const CREATE_SCENE = gql(`
mutation CREATE_SCENE(
  $id: ID!
  $projectId: Int!
  $name: String
  $notes: String
  $populationConfig: JSONObject
  $parentScenesId: [String!]
  $populationTypeAlias: PopulationTypeAlias!
) {
  createScene(
    id: $id
    projectId: $projectId
    name: $name
    notes: $notes
    populationConfig: $populationConfig
    parentScenesId: $parentScenesId
    populationTypeAlias: $populationTypeAlias
  ) {
    id
    notes
    attributes
    name
    inputQcCdnDataset {
      id
    }
    status {
      alias
    }
    vadstenaInputDmVolumeMountAlias
  }
}
`);

export const START_SCENE_POPULATION = gql(`
mutation StartScenePopulation($dmCollocationId: String!, $outputDmVolumeMountAlias: String!, $sceneId: String!, $logDmVolumeMountAlias: String!) {
  startScenePopulation(dmCollocationId: $dmCollocationId, outputDmVolumeMountAlias: $outputDmVolumeMountAlias, sceneId: $sceneId, logDmVolumeMountAlias: $logDmVolumeMountAlias) {
    id
  }
}
`);

export const UPDATE_SCENE = gql(`
mutation UPDATE_SCENE(
  $id: ID!
  $name: String
  $notes: String
  $populationConfig: JSONObject
  $attributes: JSONObject
  $aoiInputFiles: AoiInputFiles
  $requestToPopulating: Boolean
  $requestToCancelPopulation: Boolean
  $requestToCancelScene: Boolean
  $requestToInputQcReview: Boolean
  $requestToInputQcFailed: Boolean
  $requestToInputQcPassed: Boolean
  $requestToArchive: Boolean
  $requestToPurge: Boolean
  $requestToVolumeMounting: Boolean
  $requestToVolumeUnmounting: Boolean
  ) {
  updateScene(
    id: $id
    name: $name
    notes: $notes
    populationConfig: $populationConfig
    attributes: $attributes
    aoiInputFiles: $aoiInputFiles
    requestToPopulating: $requestToPopulating
    requestToCancelPopulation: $requestToCancelPopulation
    requestToCancelScene: $requestToCancelScene
    requestToInputQcReview: $requestToInputQcReview
    requestToInputQcFailed: $requestToInputQcFailed
    requestToInputQcPassed: $requestToInputQcPassed
    requestToArchive: $requestToArchive
    requestToPurge: $requestToPurge
    requestToVolumeMounting: $requestToVolumeMounting
    requestToVolumeUnmounting: $requestToVolumeUnmounting
  ) {
    id
    notes
    attributes
    name
    inputQcCdnDataset {
      id
    }
    status {
      alias
      name
    }
    project {
      id
      name
    }
    vadstenaInputDmVolumeMountAlias
    aoiGeojson
    requestToPopulating
    requestToCancelPopulation
    requestToCancelScene
    requestToInputQcReview
    requestToInputQcFailed
    requestToInputQcPassed
    requestToArchive
    requestToPurge
    requestToVolumeMounting
    requestToVolumeUnmounting
  }
}
`);
