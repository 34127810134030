import L from 'leaflet'

const getRasterTilesLayerImage = (layer, resolve, value) => {
  const image = value.image

  image.tiles = []
  image.tileSize = 256
  image.tileBounds = L.bounds(image.bounds.min.divideBy(image.tileSize)._floor(), image.bounds.max.divideBy(image.tileSize)._floor())

  for (let j = image.tileBounds.min.y; j <= image.tileBounds.max.y; j++)
    for (let i = image.tileBounds.min.x; i <= image.tileBounds.max.x; i++)
      image.tiles.push(new L.Point(i, j))

  let promiseArray = []
  image.tiles.forEach((tilePoint) => {
    let originalTilePoint = tilePoint.clone()

    let tilePos = originalTilePoint.scaleBy(new L.Point(image.tileSize, image.tileSize)).subtract(image.bounds.min)

    if (tilePoint.y < 0) return

    promiseArray.push(new Promise(resolve => {
      loadTileImage(tilePoint, tilePos, layer, resolve, value)
    }))
  })

  Promise.all(promiseArray).then(() => {
    resolve()
  })
}

const loadTileImage = (tilePoint, tilePos, layer, resolve, value) => {  
  const image = value.image
  let imgIndex = tilePoint.x + ':' + tilePoint.y + ':' + image.zoom
  image.tilesImgs[layer._leaflet_id] = {}
  let img = new Image()
  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    image.ctx.drawImage(img, tilePos.x, tilePos.y, image.tileSize, image.tileSize)
  }
  img.src = layer.getTileUrl(tilePoint)
}

export const getImage = (value) => {
  value.image = {}
  const layers = value.menuLayers
  if (layers.length == 0)
    return
  const image = value.image
  image.tilesImgs = {}
  image.markers = {}
  image.path = {}
  image.circles = {}
  image.dimensions = value.map.getSize()
  image.zoom = value.map.getZoom()
  image.bounds = value.map.getPixelBounds()
  image.canvas = document.createElement('canvas')
  image.ctx = null
  image.tileSize = 256  
  image.canvas.width = image.dimensions.x
  image.canvas.height = image.dimensions.y
  image.ctx = image.canvas.getContext('2d')

  for (const layer of layers) {
    const tileLayer = layer.tileLayer
    if (!tileLayer) continue
    let promise = new Promise(function (resolve, reject) {
      getRasterTilesLayerImage(tileLayer,resolve,value)
    })
  }
}