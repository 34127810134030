import { gql } from "graphql-tag";

/**
 *
 */
export const GET_SCENES = gql(`
query GET_SCENES($limit: Int, $offset: Int, $sort: InputSort, $filter: InputFilter) {
  getScenes(limit: $limit, offset: $offset, sort: $sort, filter: $filter) {
    pageInfo {
      limit
      offset
      total
    }
    timestamp
    scenes {
      id
      notes
      attributes
      name
      populationConfig
      inputQcCdnDataset {
        id
      }
      status {
        alias
        description
        name
      }
      timeEdited
      timeCreated
      populationType {
        alias
        configTemplate
        description
        name
      }
      vadstenaInputDmVolumeMountAlias
      project {
        id
        name
        notes
        archived
        attributes
        externalUri
      }
      statusPredicted {
        alias
        name
      }
      statusActions {
        statusAlias
        attributeName
        onRequest
      }
      automationIdle
    }
  }
}
`);

export const GET_SCENE_STATUSES = gql(`
query GetSceneStatuses {
  getSceneStatuses {
    alias
    description
    name
  }
}
`);

export const SCENE_ID_EXIST = gql(`
query SceneIdExist($sceneId: ID!) {
  sceneIdExist(sceneId: $sceneId)
}
`);

export const GET_SCENE = gql(`
query GetScene($sceneId: ID!) {
  getScene(sceneId: $sceneId) {
    id
    notes
    attributes
    name
    inputQcCdnDataset {
      id
      url
      cdnOrigin {
        id
        status {
          alias
        }
      }
    }
    imagePoseCdnDataset {
      id
      url
      cdnOrigin {
        id
        status {
          alias
        }
      }
    }
    project {
      id
      name
      notes
      externalUri
      attributes
      archived
    }
    status {
      alias
      description
      name
    }
    populationType {
      alias
      configTemplate
      description
      name
    }
    vadstenaInputDmVolumeMountAlias
    populationConfig
    aoiGeojson
    statusPredicted {
      alias
      name
    }
    statusActions {
      statusAlias
      attributeName
      onRequest
    }
    automationIdle
  }
}
`);

export const IMAGE_POSE_REQUEST = gql(`
query IMAGE_POSE_REQUEST($sceneId: String!, $viewId: String!) {
  imagePoseRequest(sceneId: $sceneId, viewId: $viewId)
}
`);

export const GET_SERIALIZED_POPULATION_CONFIG = gql(`
query GET_SERIALIZED_POPULATION_CONFIG($sceneId: ID!) {
  getSerializedPopulationConfig(sceneId: $sceneId)
}
`);

export const GetSceneRelations = gql(`
query GetSceneRelations($sceneId: ID!) {
  getScene(sceneId: $sceneId) {
    id
    name
    project {
        id
        name
    }
    parentScenes {
      id
      name
      project {
        id
        name
      }
    }
    childScenes {
      id
      name
      project {
        id
        name
      }
    }
  }
}
`);
