<template>
    <div :class="containerClass" @click="onDocumentClick($event)">
        <Toast position="bottom-right"/>
        <ConfirmDialog></ConfirmDialog>
        
        <AppTopBar
            :topbarTheme="topbarTheme"
            :activeTopbarItem="activeTopbarItem"
            @menu-button-click="onMenuButtonClick($event)"
            @right-panel-click="onRightPanelButtonClick"
            @topbar-item-click="onTopbarItemClick"
            @search-click="onSearchClick"
        ></AppTopBar>

        <AppRightPanel @right-panel-click="onRightPanelClick"></AppRightPanel>

        <div class="menu-wrapper" @click="onMenuClick($event)">
            <AppMenu :model="menu" :active="menuActive" :mobileMenuActive="staticMenuMobileActive" :menuMode="menuMode" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"></AppMenu>
        </div>

        <div class="layout-main">
            <AppBreadcrumb></AppBreadcrumb>

            <div class="layout-content">
                <router-view />
            </div>

            <AppFooter></AppFooter>
        </div>

        <AppConfig
            v-model:theme="theme"
            v-model:layoutMode="layoutMode"
            v-model:menuMode="menuMode"
            v-model:menuTheme="menuTheme"
            v-model:topbarTheme="topbarTheme"
            :configActive="configActive"
            :configClick="configClick"
            @config-click="onConfigClick"
            @config-button-click="onConfigButtonClick"
        ></AppConfig>

        <div v-if="staticMenuMobileActive" class="layout-mask modal-in"></div>
    </div>
</template>

<script>
import EventBus from './event-bus';
import AppTopBar from './AppTopbar.vue';
import AppMenu from './AppMenu.vue';
import AppFooter from './AppFooter.vue';
import AppConfig from './AppConfig.vue';
import AppRightPanel from './AppRightPanel.vue';
import AppBreadcrumb from './AppBreadcrumb.vue';
import SidebarLinks from './SidebarLinks.js'

import { useTheme } from '@/composables/useTheme';
const { getTheme } = useTheme()

export default {
  data() {
    const config = getTheme()

    this.$primevue.config.ripple = config.ripple
    this.$primevue.config.inputStyle = config.inputStyle
    this.$appState.isRTL = config.isRTL

    return {
      ...config,
      activeTopbarItem: null,
      menuActive: false,
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
      menuClick: false,
      searchActive: false,
      searchClick: false,
      userMenuClick: false,
      topbarMenuActive: false,
      rightPanelClick: false,
      rightPanelActive: false,
      configActive: false,
      configClick: false,
      menu: SidebarLinks,
    };
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': this.menuMode === 'overlay',
          'layout-static': this.menuMode === 'static',
          'layout-horizontal': this.menuMode === 'horizontal',
          'layout-overlay-active': this.overlayMenuActive,
          'layout-mobile-active': this.staticMenuMobileActive,
          'layout-static-active': !this.staticMenuDesktopInactive && this.menuMode === 'static',
          'layout-rightpanel-active': this.rightPanelActive,
          'layout-rtl': this.$appState.isRTL,
          'p-input-filled': this.$primevue.config.inputStyle === 'filled',
          'p-ripple-disabled': !this.$primevue.config.ripple,
        },
        'layout-menu-' + this.menuTheme + ' layout-topbar-' + this.topbarTheme,
      ];
    },
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onDocumentClick() {
      if (!this.searchClick) {
        this.searchActive = false;
      }

      if (!this.topbarItemClick) {
        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.userMenuClick) {
        this.topbarMenuActive = false;
      }

      if (!this.rightPanelClick) {
        this.rightPanelActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal()) {
          EventBus.emit('reset-active-index');
          this.menuActive = false;
        }

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
          this.hideOverlayMenu();
        }

        this.unblockBodyScroll();
      }

      if (this.configActive && !this.configClick) {
        this.configActive = false;
      }

      this.searchClick = false;
      this.configClick = false;
      this.topbarItemClick = false;
      this.rightPanelClick = false;
      this.menuClick = false;
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuButtonClick(event) {
      this.rotateMenuButton = !this.rotateMenuButton;
      this.topbarMenuActive = false;
      this.menuClick = true;

      if (this.isOverlay() && !this.isMobile()) {
        this.overlayMenuActive = !this.overlayMenuActive;
      }

      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
        if (this.staticMenuMobileActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      }

      event.preventDefault();
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit('reset-active-index');
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isHorizontal()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onRightPanelClick() {
      this.rightPanelClick = true;
    },
    onRightPanelButtonClick(event) {
      this.rightPanelClick = true;
      this.rightPanelActive = !this.rightPanelActive;
      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item) {
        this.activeTopbarItem = null;
      } else {
        this.activeTopbarItem = event.item;
      }

      if (event.item === 'search') {
        this.searchActive = !this.searchActive;
        this.searchClick = !this.searchClick;
      }

      event.originalEvent.preventDefault();
    },
    onSearchClick(event) {
      this.searchClick = true;
      event.stopPropagation();
    },
    onConfigClick() {
      this.configClick = true;
    },
    onConfigButtonClick() {
      this.configActive = !this.configActive;
      this.configClick = true;
    },
    hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
      this.unblockBodyScroll();
    },
    blockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.add('blocked-scroll');
      } else {
        document.body.className += ' blocked-scroll';
      }
    },
    unblockBodyScroll() {
      if (document.body.classList) {
        document.body.classList.remove('blocked-scroll');
      } else {
        document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      }
    },
    isTablet() {
      const width = window.innerWidth;
      return width <= 1024 && width > 640;
    },
    isDesktop() {
      return window.innerWidth > 992;
    },
    isMobile() {
      return window.innerWidth <= 640;
    },
    isOverlay() {
      return this.menuMode === 'overlay';
    },
    isStatic() {
      return this.menuMode === 'static';
    },
    isHorizontal() {
      return this.menuMode === 'horizontal';
    },
  },
  components: {
    AppTopBar,
    AppMenu,
    AppFooter,
    AppConfig,
    AppRightPanel,
    AppBreadcrumb,
  },
};
</script>
