import ProjectList from '../../components/Admin/Project/ProjectList.vue'
import ProjectDetailt from '../../components/Admin/Project/ProjectDetail.vue'
import ProjectCreate from '../../components/Admin/Project/ProjectCreate.vue'
import ProjectEdit from '../../components/Admin/Project/ProjectEdit.vue'

import UpdateScene from '../../components/Admin/Project/Scene/UpdateScene.vue'
import CreateScene from '../../components/Admin/Project/Scene/CreateScene.vue'
import StepOne from '../../components/Admin/Project/Scene/CreateSceneSteps/StepOne.vue'
import StepOverview from '../../components/Admin/Project/Scene/CreateSceneSteps/StepOverview.vue'
import SensorDefinitions from '../../components/Admin/Project/Scene/CreateSceneSteps/SensorDefinitions.vue'
import InputConfigurations from '../../components/Admin/Project/Scene/CreateSceneSteps/InputConfigurations.vue'

import ProjectSceneDetail from '@/components/Admin/Project/Scene/ProjectSceneDetail.vue'

import { RouteRecordRaw } from 'vue-router'

export const projectRoutes: RouteRecordRaw[] = [
  {
    path: 'project',
    name: 'project-list',
    component: ProjectList,
    meta: {
      can: 'is-admin',
      breadcrumb: [{ label: 'Projects' }]
    },
  },
  {
    path: 'project/create',
    name: 'project-create',
    component: ProjectCreate,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Projects', name: 'project-list' },
        { label: 'Create project' }
      ]
    }
  },
  {
    path: 'project/:projectId',
    name: 'project-detail',
    component: ProjectDetailt,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Projects', name: 'project-list' },
        { label: 'Project detail' }
      ]
    },
    props: true,
  },
  {
    path: 'project/:projectId/edit',
    name: 'project-edit',
    component: ProjectEdit,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Projects', name: 'project-list' },
        { label: 'Project edit' }
      ]
    },
    props: true
  },
  {
    path: 'project/:projectId/scene/:sceneId/edit',
    name: 'update-scene',
    component: UpdateScene,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Projects', name: 'project-list' },
        { label: 'Project detail', name: 'project-detail' },
        { label: 'Scene edit' }
      ]
    },
    props: true,
    redirect: { name: 'StepOneEdit' },
    children: [
      {
        path: 'step-one',
        name: 'StepOneEdit',
        component: StepOne,
      },
      {
        path: 'sensor-definitions',
        name: 'SensorDefinitionsEdit',
        component: SensorDefinitions,
      },
      {
        path: 'input-configurations',
        name: 'InputConfigurationsEdit',
        component: InputConfigurations,
      },      
      {
        path: 'step-overview',
        name: 'StepOverviewEdit',
        component: StepOverview,
      },
    ]
  },
  {
    path: 'project/:projectId/create-scene',
    name: 'create-scene',
    component: CreateScene,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Projects', name: 'project-list' },
        { label: 'Project detail', name: 'project-detail' },
        { label: 'Create scene' }
      ]
    },
    props: true,
    redirect: { name: 'StepOne' },
    children: [
      {
        path: 'step-one',
        name: 'StepOne',
        component: StepOne,
      },
      {
        path: 'sensor-definitions',
        name: 'SensorDefinitions',
        component: SensorDefinitions,
      },
      {
        path: 'input-configurations',
        name: 'InputConfigurations',
        component: InputConfigurations,
      },      
      {
        path: 'step-overview',
        name: 'StepOverview',
        component: StepOverview,
      },
    ]
  },
  {
    path: 'project/:projectId/scene/:sceneId',
    name: 'project-scene-detail',
    component: ProjectSceneDetail,
    meta: {
      can: 'is-admin',
      breadcrumb: [
        { label: 'Projects', name: 'project-list' },
        { label: 'Project detail', name: 'project-detail' },
        { label: 'Scene detail' }
      ]
    },
    props: true
  }
]
