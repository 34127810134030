import { REGISTER_VOLUME, UPDATE_VOLUME, UNREGISTER_REGISTERED_VOLUME, REGISTER_UNREGISTERED_VOLUME } from '@/apollo/mutations/data_mgmt'
import { GET_VOLUME, GET_VOLUMES, GET_VOLUME_STATUSES } from '@/apollo/queries/data_mgmt'
import { QueryGetVolumesArgs, Volume, VolumeCreateInput, VolumeStatus, VolumeUpdateInput } from '@/gql/graphql'
import { ApolloError } from '@apollo/client/errors'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { ComputedRef, computed, Ref, ref } from 'vue'

export function useVolumes() {

  /**
   * @description
   * @interface mutationResult
   */
  interface mutationResult {
    /**
     * @description success
     * @type {boolean}
     * @memberof mutationResult
     */
    success?: boolean,
    /**
     * @description payload
     * @type {{
     *       volume: Volume
     *     }}
     * @memberof mutationResult
     */
    payload?: {
      volume: Volume
    },
    /**
     * @description error
     * @type {*}
     * @memberof mutationResult
     */
    error?: any,

    message?: string
  }

  const { mutate: registerVolumeMutation } = useMutation(REGISTER_VOLUME)
  const { mutate: updateVolumeMutation } = useMutation(UPDATE_VOLUME)
  const { mutate: unregisterRegisteredVolumeMutation } = useMutation(UNREGISTER_REGISTERED_VOLUME)
  const { mutate: registerUnregisteredVolumeMutation } = useMutation(REGISTER_UNREGISTERED_VOLUME)

  /**
   * @description
   * @param {Volume} volume
   * @return {*}  {Promise<mutationResult>}
   */
  async function registerVolume(volume: VolumeCreateInput): Promise<mutationResult> {
    const result: mutationResult = {}
    try {
      const res = await registerVolumeMutation({ input: volume })
      if (res?.data?.registerVolume) {
        result.success = true
        result.payload = {
          // @ts-ignore
          volume: res?.data?.registerVolume
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }

  /**
   * @description updates volume
   * @param {Volume} volume
   * @return {*}  {Promise<mutationResult>}
   */
  async function updateVolume(volume: VolumeUpdateInput): Promise<mutationResult> {
    const result: mutationResult = {}
    try {
      const res = await updateVolumeMutation({ input: volume })
      if (res?.data?.updateVolume) {
        result.success = true
        result.payload = {
          // @ts-expect-error
          volume: res?.data?.updateVolume
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }

  /**
   * @description
   * @param {string} storageId
   * @param {string} volumeName
   * @return {*}  
   */
  async function generateNewVolumeMountAlias(storageId: string, volumeName: string) {
    const volumeMountAlias: Ref<string> = ref('')
    try {
      if (typeof storageId === 'string') {
        volumeMountAlias.value = cleanLinuxFileName(storageId + '_' + volumeName)
      }
    } catch (err) {
      console.log(err)
    }
    return { volumeMountAlias }
  }

  /**
   * @description returns string valid for unix file name to use without ""
   * @param {string} inputString
   * @return {*}  {string}
   */
  function cleanLinuxFileName(inputString: string): string {
    const cleanedString = inputString
      .trim() // trim leading and trailing whitespace
      .normalize('NFD') // convert accented characters to their base characters
      .replace(/[\u0300-\u036f]/g, '') // remove diacritical marks
      .replace(/[^\w-]|/g, ''); // remove all characters that are not word characters, hyphens

    return cleanedString
  }

  /**
   * @description returns filtered volumes
   * @param {string} volumeMountAlias
   * @return {*}  
   */
  function getVolumes(query: QueryGetVolumesArgs | {} = {}, pollInterval: number | undefined = 0) {
    const { result, loading, error, refetch } = useQuery(GET_VOLUMES, query, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      pollInterval
    })
    const volumes: ComputedRef<Volume[]> = computed(() => result?.value?.getVolumes?.volumes ?? [])
    return { volumes, error, loading, refetch }
  }

  /**
   * @description get enum of possible volume statuses
   * @return {*}  
   */
  function getVolumeStatuses() {
    const { result, loading, error } = useQuery(GET_VOLUME_STATUSES)
    const volumeStatuses: ComputedRef<VolumeStatus[]> = computed(() => result?.value?.getVolumeStatuses ?? [])
    return { volumeStatuses, error, loading }
  }
  /**
   * @description returns volume by id
   * @param {string} mountAlias
   * @return {*}  
   */
  function getVolume(mountAlias: string, pollInterval: number | undefined = 0) {
    const { result, error, loading, refetch } = useQuery(GET_VOLUME, {
      mountAlias,
    }, {
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
      pollInterval
    })
    const volume: ComputedRef<Volume> = computed(() => result?.value?.getVolume ?? null)
    return { volume, error, loading, refetch: (mountAlias: string) => refetch({ mountAlias }) }
  }

  /**
   * @description unregistrate volume from system
   * @param {string} mountAlias
   * @return {*}  {Promise<mutationResult>}
   */
  async function unregisterRegisteredVolume(mountAlias: string): Promise<mutationResult> {
    try {
      await unregisterRegisteredVolumeMutation({
        mountAlias
      })
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error,
        message: error instanceof ApolloError ? error.message : undefined
      }
    }
  }

  /**
   * @description unregistrate volume from system
   * @param {string} volumvolumeMountAliaseId
   * @return {*}  {Promise<mutationResult>}
   */
  async function registerUnregisteredVolume(mountAlias: string): Promise<mutationResult> {
    try {
      await registerUnregisteredVolumeMutation({
        mountAlias
      })
      return { success: true };
    } catch (error) {
      return {
        success: false,
        error,
        message: error instanceof ApolloError ? error.message : undefined
      }
    }
  }


  return {
    getVolume,
    getVolumes,
    getVolumeStatuses,
    generateNewVolumeMountAlias,
    registerVolume,
    unregisterRegisteredVolume,
    registerUnregisteredVolume,
    updateVolume
  }
}