<template>
      <div class="login-body">
        <div class="login-wrapper">
            <div class="login-panel">
              <span class="logo">
                <img id="app-logo" :class="(topbarTheme === 'dark' ? 'ligth' : 'dark')" src="@/assets/vadstena-logo.png" />
              </span>
              <div class="login-form">
                <h2>Login</h2>
                <p>You don't have an account? <a @click="login('register')"> Sign up</a></p>
                <Button icon="fa-brands fa-microsoft" label="Login with microsoft" type="button" @click="login('login')"></Button>
              </div>

              <p>A problem? <router-link to="/">Click here</router-link> and let us help you.</p>
          </div>
        </div>
        <Toast/>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useMsal } from '@/auth/composables/useMsal'
import { loginRequest } from '@/auth/authConfig'

import BrowserDetector from 'browser-dtector'
import { EventType } from "@azure/msal-browser"

import { useMutation } from '@vue/apollo-composable'
import { USER_REGISTER_MICROSOFT, USER_LOGIN_MICROSOFT } from '@/apollo/mutations/auth'
import { useToast } from "primevue/usetoast"

const LOGIN_TYPE_KEY = '_hndw-login-type'

export default {
  setup() {
    const { mutate: registrationAPI } = useMutation(USER_REGISTER_MICROSOFT)
    const { mutate: loginAPI } = useMutation(USER_LOGIN_MICROSOFT)

    const toast = useToast()

    const _loginType = window.localStorage.getItem(LOGIN_TYPE_KEY)    
    const loginType = ref((_loginType === 'login' || _loginType === 'register') ? _loginType : 'login')

    const { instance } = useMsal()

    const tokenAcquired = async (payload) => {
      try {
        if (payload && payload.accessToken) {
          if (loginType.value === 'login') {
            let res = await loginAPI({
              accessToken: payload.accessToken
            })

            if(res && res.data && res.data.loginMicrosoft === 404) {
              toast.add({severity:'error', summary: 'Error', detail:'Account not found.', life: 3000})
              return
            }

            if (window.localStorage.getItem('gc-auth-user')) {
              window.localStorage.setItem('event-login', 'login-' + Math.random())
            }

            window.localStorage.setItem('gc-auth-token', res.data.loginMicrosoft ? res.data.loginMicrosoft.token : '')
            window.localStorage.setItem('gc-auth-user', res.data.loginMicrosoft? JSON.stringify(res.data.loginMicrosoft.user): JSON.stringify({}))
            window.localStorage.removeItem(LOGIN_TYPE_KEY)

            // force replace
            window.location.href = '/'
          } else if(loginType.value === 'register') {
            if (payload) {
              let res = await registrationAPI({
                accessToken: payload.accessToken
              })
          
              if(res && res.data && res.data.registerUserMicrosoft && res.data.registerUserMicrosoft.token){
                toast.add({severity:'success', summary: 'Succes', detail:'Account successfully created. Contact us due to activation', life: 3000})  
              }

              window.localStorage.setItem('gc-auth-token', res.data.registerUserMicrosoft ? res.data.registerUserMicrosoft.token : null)
              window.localStorage.removeItem(LOGIN_TYPE_KEY)
            }
          }
        }
      } catch (err) {
        if(err && err.name === 'ApolloError' && err.graphQLErrors.length && err.graphQLErrors.findIndex(item => item.extensions?.code === "RESOURCE_NOT_FOUND") >= 0) {
          toast.add({ severity: 'error', summary: 'Error', detail: 'Account not found. Sign up firstly.', life: 3000})
          return
        } 
        
        toast.add({ severity: 'error', summary: 'Error', detail: err.message ?? 'Unknown error occured...', life: 3000 })
        return  
      }
    }

    instance.setEventCallback(EventType.ACQUIRE_TOKEN_SUCCESS, tokenAcquired)

    const isApple = () => {
      const detector = new BrowserDetector(window.navigator.userAgent)
      const userAgent = detector.parseUserAgent()

      let applePlatforms = ["Macintosh", "IPad", "IPhone"]

      if (applePlatforms.indexOf(userAgent.platform) >= 0 || userAgent.isWebkit) {
        return true
      }
    
      return false
    }


    const login = (type) => {
      loginType.value = type
      window.localStorage.setItem(LOGIN_TYPE_KEY, type)

      if (isApple()) {
        instance.loginRedirect(loginRequest)
      } else {
        instance.loginPopup(loginRequest)
      }
    }

    return {
      login
    }
  },
}
</script>


<style lang="scss" scoped>
#app-logo {
  height: 72px;
  margin-top: 5px;
  &.dark {
    filter: invert(1);
  }
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-datatable) {
    &.p-datatable-customers {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr {
        border-bottom: 1px solid #dee2e6;
        > td {
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0 none !important;
          width: 100% !important;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            min-width: 30%;
            display: inline-block;
            margin: -.4rem 1rem -.4rem -.4rem;
            font-weight: bold;
          }

          .p-progressbar {
            margin-top: .5rem;
          }
        }
      }
    }
  }
}
</style>
