import L from 'leaflet'
import { Symbolizer } from './Symbolizer.js'
import { PolyBase } from './Symbolizer.PolyBase.js'

// 🍂class FootprintSymbolizer
// 🍂inherits CircleMarker
// A symbolizer for points.

export var FootprintSymbolizer = L.CircleMarker.extend({
  includes: Symbolizer.prototype,

  statics: {
    iconCache: {}
  },

  initialize: function(feature, pxPerExtent) {
    this.properties = feature.properties
    this._makeFeatureParts(feature, pxPerExtent)
  },

  render: function(renderer, style) {
    Symbolizer.prototype.render.call(this, renderer, style)
    this._radius = style.radius || L.CircleMarker.prototype.options.radius
    this._radius = 4
    this._updatePath()
  },

  _makeFeatureParts: function(feat, pxPerExtent) {
    var coord = feat.geometry[0]    
    if (typeof coord[0] === 'object' && 'x' in coord[0]) {
      // Protobuf vector tiles return [{x: , y:}]
      let x = 0, y = 0
      for (let i = 0; i < coord.length - 1; i++) {
        x += coord[i].x
        y += coord[i].y
      }
      x /= coord.length - 1
      y /= coord.length - 1
      //this._point = L.point(coord[0]).scaleBy(pxPerExtent)
      this._point = L.point({x, y}).scaleBy(pxPerExtent)
      this._empty = L.Util.falseFn
    } else {
      // Geojson-vt returns [,]
      this._point = L.point(coord).scaleBy(pxPerExtent)
      this._empty = L.Util.falseFn
    }
  },

  makeInteractive: function() {
    this._updateBounds()
  },

  updateStyle: function(renderer, style) {
    this._radius = style.radius || this._radius
    this._updateBounds()
    return Symbolizer.prototype.updateStyle.call(this, renderer, style)
  },

  _updateBounds: function() {
    var icon = this.options.icon
    if (icon) {
      var size = L.point(icon.options.iconSize),
        anchor = icon.options.iconAnchor ||
                   size && size.divideBy(2, true),
        p = this._point.subtract(anchor)
      this._pxBounds = new L.Bounds(p, p.add(icon.options.iconSize))
    } else {
      L.CircleMarker.prototype._updateBounds.call(this)
    }
  },

  _updatePath: function() {
    if (this.options.icon) {
      this._renderer._updateIcon(this)
    } else {
      L.CircleMarker.prototype._updatePath.call(this)
    }
  },

  _getImage: function () {
    if (this.options.icon) {
      var url = this.options.icon.options.iconUrl,
        img = FootprintSymbolizer.iconCache[url]
      if (!img) {
        var icon = this.options.icon
        img = FootprintSymbolizer.iconCache[url] = icon.createIcon()
      }
      return img
    } else {
      return null
    }

  },

  _containsPoint: function(p) {
    var icon = this.options.icon
    if (icon) {
      return this._pxBounds.contains(p)
    } else {
      return L.CircleMarker.prototype._containsPoint.call(this, p)
    }
  }
})

