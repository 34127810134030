import { CdnOriginStatusAlias, CdnOriginTypeAlias, DataMgmtTaskTypeAlias } from "@/gql/graphql"

export enum ApiTaskStatusAlias {
  Canceled = "Canceled",
  Created = "Created",
  Done = "Done",
  Errored = "Errored",
  Processing = "Processing",
}

export const taskStatusMapper = {
  [ApiTaskStatusAlias.Created]: {
    severity: "info",
    iconClass: "fa-solid fa-clock",
    label: "Created",
    cancelButtonVisible: true
  },
  [ApiTaskStatusAlias.Processing]: {
    severity: "success",
    iconClass: "fa-solid fa-check",
    label: "Processing",
    cancelButtonVisible: true
  },
  [ApiTaskStatusAlias.Canceled]: {
    severity: "warning",
    iconClass: "fa-solid fa-xmark",
    label: "Canceled",
    cancelButtonVisible: true
  },
  [ApiTaskStatusAlias.Errored]: {
    severity: "danger",
    iconClass: "fa-solid fa-exclamation-triangle",
    label: "Errored",
    cancelButtonVisible: false
  },
  [ApiTaskStatusAlias.Done]: {
    severity: "info",
    iconClass: "fa-solid fa-stop",
    label: "Done",
    cancelButtonVisible: false
  },
  "other": {
    severity: "danger",
    iconClass: "fa-solid fa-minus",
    label: "other",
    cancelButtonVisible: true
  }
}

export const taskTypeMapper = {
  [DataMgmtTaskTypeAlias.DataBrowserService]: {
    severity: "secondary",
    iconClass: "fa-solid fa-folder",
    label: "Data Browser Service"
  },
  [DataMgmtTaskTypeAlias.FileProviderService]: {
    severity: "primary",
    iconClass: "fa-solid fa-file",
    label: "File Provider"
  },
  [DataMgmtTaskTypeAlias.ImagePoseService]: {
    severity: "warning",
    iconClass: "fa-solid fa-image",
    label: "Image Pose Service"
  },
  [DataMgmtTaskTypeAlias.GeoConversionService]: {
    severity: "warning",
    iconClass: "fa-solid fa-image",
    label: "Geo Conversion Service"
  },  
  // [TaskTypeAlias.ImageCropperService]: {
  //   severity: "warning",
  //   iconClass: "fa-solid fa-image",
  //   label: "Image Cropper Service"
  // },
  "other": {
    severity: "danger",
    iconClass: "fa-solid fa-list",
    label: "Other"
  }
}

export const cdnOriginStatusMapper = {
  [CdnOriginStatusAlias.Initializing]: {
    severity: "info",
    iconClass: "fa-solid fa-clock",
    label: "Initializing"
  },
  [CdnOriginStatusAlias.Running]: {
    severity: "success",
    iconClass: "fa-solid fa-check",
    label: "Running"
  },
  [CdnOriginStatusAlias.Stopping]: {
    severity: "warning",
    iconClass: "fa-solid fa-xmark",
    label: "Stopping"
  },
  [CdnOriginStatusAlias.Stopped]: {
    severity: "danger",
    iconClass: "fa-solid fa-stop",
    label: "Stopped"
  },
}

export const cdnOriginTypeMapper = {
  [CdnOriginTypeAlias.FileProviderService]: {
    severity: "primary",
    iconClass: "fa-solid fa-file",
    label: "File Provider"
  },
  [CdnOriginTypeAlias.ImagePoseService]: {
    severity: "warning",
    iconClass: "fa-solid fa-image",
    label: "Image Pose Service"
  }
}

export const other = {
  severity: "danger",
  iconClass: "fa-solid fa-list",
  label: "Other"
}