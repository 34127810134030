import { GET_API_VERSION } from "@/apollo/queries/about"
import { useQuery } from "@vue/apollo-composable"
import { ComputedRef, computed } from "vue"

export function useVersion() {

  /**
   * @description
   * @param {string} get api version
   * @return {*}  
   */
  function getApiVersion() {
    const { result, error, loading } = useQuery(GET_API_VERSION, {}, {
      errorPolicy: 'all'
    })
    const apiVersion : ComputedRef<string> = computed(() => result.value?.getApiVersion?.version ?? '-')
    return { apiVersion, error, loading }
  }

  function getUIVersion() {
    const uiVersion : ComputedRef<string> = computed(() => process.env.VUE_APP_VERSION ?? '-')
    return { uiVersion }
  }
  
  return { getApiVersion, getUIVersion }
}