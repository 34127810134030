<template>
    <div class="layout-breadcrumb">
        <div class="breadcrumb">
            <div class="route-bar-breadcrumb">
                <template v-for="(item, index) of items" :key="item">
                    <li>
                        <router-link v-if="item.router && index < (items.length - 1)" :to="item.router" class="p-link">{{ item.label }}</router-link>
                        <template v-else>{{ item.label }}</template>
                    </li>
                    <li v-if="index < (items.length - 1)"><i :class="['pi', { 'pi-angle-right': !$appState.isRTL, 'pi-angle-left': $appState.isRTL }]"></i></li>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  data() {
    return {
      home: { icon: 'pi pi-home', to: '/' },
      items: [],
    };
  },
  watch: {
    $route() {
      this.watchRouter();
    },
  },
  created() {
    this.watchRouter();
  },
  methods: {
    watchRouter() {
      this.items = []
      for (let route of this.$router.currentRoute.value.matched) {
        for (let breadcrumb of route.meta.breadcrumb || []) {
          this.items.push({
            label: breadcrumb.label,
            router: {
              name: breadcrumb.name,
              params: this.$route.params,
            }
          })
        }
      }
    },
  },
};
</script>
