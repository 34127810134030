export enum PRESET_TYPES {
  CAMERA_DEF = 'CameraDef',
  LIDAR_DEF = 'LidarDef',
  SCENE_OPTIONS = 'SceneOptions',
  TRAJECTORY_DEF = 'TrajectoryDef',
  CAMERA_RULES = 'CameraRules',
  LIDAR_RULES = 'LidarRules',
  TRAJECTORY_RULES = 'TrajectoryRules',
  DEM_DEF = 'DemDef'
}

// TODO obtain from grapqhl
export enum TaskStatusAlias {
  Created = "Created",
  Processing = "Processing",
  Done = "Done"
}

// TODO obtain from grapqhl
export enum TaskTypeAlias {
  Ingest = "Ingest",
  FileProvider = "FileProvider",
  ImagePose = "ImagePose",
  DataBrowseService = "DataBrowseService",
  CopyVolume = "CopyVolume",
  ClusterProvisioning = "ClusterProvisioning",
  ClusterService = "ClusterService",
  ClusterCleanUp = "ClusterCleanUp"
}