<template>
	<div v-if="preset">
    <div class="grid p-fluid float-label">
        <div class="col-12 md:col-6">
            <div class="field">
                <span class="p-float-label">
                  <InputText :disabled="update" id="key" v-model="v$.key.$model" :class="{'p-invalid':v$.key.$invalid && submitted}" />
                  <label for="key" :class="{'p-error':v$.key.$invalid && submitted}">Unique identifier of preset *</label>
                </span>
                <small v-if="(v$.key.$invalid && submitted) || v$.key.$pending.$response" class="p-error">{{v$.key.required.$message.replace('Value', 'unique identifier')}}</small>
            </div>
            <div class="field">
                <span class="p-float-label">
                  <Dropdown scroll-height="400px"
                    :disabled="update"
                    id="presetTypeAlias" class="full-width" v-model="v$.presetTypeAlias.$model" :class="{'p-invalid':v$.presetTypeAlias.$invalid && submitted}"
                    :options="presetTypes" optionLabel="name" optionValue="alias"  placeholder="Select preset type"
                  />
                  <label for="presetTypeAlias" :class="{'p-error':v$.presetTypeAlias.$invalid && submitted}">Preset type *</label>
                </span>
                <small v-if="(v$.presetTypeAlias.$invalid && submitted) || v$.presetTypeAlias.$pending.$response" class="p-error">{{v$.presetTypeAlias.required.$message.replace('Value', 'preset type')}}</small>
            </div>
        </div>
    </div>
    <Divider align="center" type="dashed">
        <span class="p-tag">Preset value {{ preset?.presetTypeAlias }}</span>
    </Divider>
    <div class="preset-value-form"  :class="{'p-invalid':v$.value.$invalid && submitted}">
      <scene-options-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.SCENE_OPTIONS"
        v-model="v$.value.$model"
      ></scene-options-preset-form>
      <camera-def-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.CAMERA_DEF"
        v-model="v$.value.$model"
      ></camera-def-preset-form>
      <lidar-def-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.LIDAR_DEF"
        v-model="v$.value.$model"
      ></lidar-def-preset-form>
      <trajectory-def-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.TRAJECTORY_DEF"
        v-model="v$.value.$model"
      ></trajectory-def-preset-form>
      <rules-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.CAMERA_RULES"
        v-model="v$.value.$model"
        title="Camera rules"
        :type="PRESET_TYPES.CAMERA_RULES"
      ></rules-preset-form> 
      <rules-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.LIDAR_RULES"
        v-model="v$.value.$model"
        title="Lidar rules"
        :type="PRESET_TYPES.LIDAR_RULES"
      ></rules-preset-form> 
      <rules-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.TRAJECTORY_RULES"
        v-model="v$.value.$model"
        title="Trajectory rules"
        :type="PRESET_TYPES.TRAJECTORY_RULES"
      ></rules-preset-form>
      <rules-preset-form
        v-if="preset && preset.presetTypeAlias === PRESET_TYPES.DEM_DEF"
        v-model="v$.value.$model"
        title="DEM definition"
        :type="PRESET_TYPES.DEM_DEF"
      ></rules-preset-form>               
      <small v-if="(v$.value.$invalid && submitted) || v$.value.$pending.$response" class="p-error">{{v$.value.required.$message.replace('Value', 'preset value')}}</small>
      <div class="flex flex-row flex-wrap justify-content-end">
        <Button label="Save" icon="fa-solid fa-check" @click="handleSubmit(!v$.$invalid)" class="p-button-success mr-2 mb-2"></Button>
        <router-link :to="{ name: 'presets' }">
          <Button label="Cancel" icon="fa-solid fa-xmark" class="p-button-secondary mr-2 mb-2" />
        </router-link>  
      </div>
    </div>
  </div>
  <div v-else>
    Loading...
  </div>
</template>

<script setup>
import { useToast } from 'primevue/usetoast'

import SceneOptionsPresetForm from '../forms/SceneOptionsPresetForm.vue'
import CameraDefPresetForm from '../forms/CameraDefPresetForm.vue'
import LidarDefPresetForm from '../forms/LidarDefPresetForm.vue'
import TrajectoryDefPresetForm from '../forms/TrajectoryDefPresetForm.vue'
import RulesPresetForm from '../forms/RulesPresetForm.vue'
import {  required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { ref, watch } from 'vue'
import { PRESET_TYPES } from '@/helpers/constants'

import { usePresets } from '@/composables/usePresets'
import router from '@/router'

const props = defineProps({
  presetKey: {
    type: String,
    required: false
  },
  update: {
    type: Boolean,
    default: false
  }
})

const { updatePreset, createPreset, getPreset, getPresetTypes } = usePresets()

const toast = useToast()

let preset = null
if (props.update) {
  preset = getPreset(props.presetKey).preset
  watch(preset, (newValue) => {
    if (newValue && newValue.presetType ) {
      preset.value.presetTypeAlias = newValue.presetType.alias
    }
  })
} else {
  preset = ref({
    key: null,
    presetTypeAlias: null,
    value: null
  })
}

const { presetTypes } = getPresetTypes()

const rules = {
  key: { required },
  presetTypeAlias: { required },
  value: { required }
}
const submitted = ref(false)
const v$ = useVuelidate(rules, preset)

const onUpdatePreset = async () => {
  try {
    let res = await updatePreset({
      key: preset.value.key,
      value: JSON.parse(JSON.stringify(preset.value.value)),
    })
    if(res.success){
      toast.add({severity:'success', summary: 'Success', detail:'Succesfully updated', life: 3000})
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while saving', life: 3000})
    console.error(err)
  }
}

const onCreatePreset = async () => {
  try {
    let _value = JSON.parse(JSON.stringify(preset.value.value))

    let res = await createPreset({
      key: preset.value.key,
      presetTypeAlias: preset.value.presetTypeAlias,
      value: {
        [preset.value.presetTypeAlias]: _value[preset.value.presetTypeAlias]
      },
    })

    if(res.success){
      toast.add({ severity: 'success', summary: 'Success', detail: 'Succesfully created', life: 3000 })
      router.push({
        name: 'preset-detail',
        params: {
          presetKey: res?.payload?.preset?.key
        }
      })
    } else {
      toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating', life: 3000})
    }
  } catch (err) {
    toast.add({severity:'error', summary: 'Error', detail:'Error occured while creating. ' + err.message, life: 3000})
    console.error(err)
  }
}

const handleSubmit = async (isFormValid) => {
  submitted.value = true
  if (!isFormValid) {
    return
  }

  if (props.update) {
    onUpdatePreset()
  } else {
    onCreatePreset()
  }
}
</script> 

<style lang="scss">
.p-datatable-loading-overlay {
    display: none !important;
}
</style>