import SemanticFeaturesQc from '@/components/Admin/QualityControl/SemanticFeatures/SemanticFeaturesQc.vue'
import MeshQc from '@/components/Admin/QualityControl/Mesh/MeshQc.vue'
import { RouteRecordRaw } from 'vue-router'

export const qualityControlRoutes: RouteRecordRaw[] = [
  {
    path: 'quality-control/mesh',
    name: 'quality-control-mesh',
    component: MeshQc,
    meta: {
      can: 'is-admin'
    },
    props: true
  },
  {
    path: 'quality-control/semantic-features',
    name: 'quality-control-semantic-features',
    component: SemanticFeaturesQc,
    meta: {
      can: 'is-admin'
    },
    props: true
  }
]
