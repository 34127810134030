import { ComputedRef, computed } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { GET_PROJECT, GET_PROJECTS } from '@/apollo/queries/project'
import { PageInfo, Project, QueryGetProjectsArgs,  }  from '@/gql/graphql'
import { CREATE_PROJECT, UPDATE_PROJECT } from '@/apollo/mutations/project'

/**
 * @description
 * @interface mutationResult
 */
interface projectMutationResult {
  /**
   * @description success
   * @type {boolean}
   * @memberof mutationResult
   */
  success?: boolean,
  /**
   * @description payload
   * @type {{
   *       project: Project
   *     }}
   * @memberof mutationResult
   */
  payload?: {
    project: Project
  },
  /**
   * @description error
   * @type {*}
   * @memberof mutationResult
   */
  error?: any,

  message?: string
}

export function useProject() {
  const { mutate: createProjectMutation } = useMutation(CREATE_PROJECT)
  const { mutate: updateProjectMutation } = useMutation(UPDATE_PROJECT)

  /**
   * @description
   * @param {id} projectId
   * @return {*}  
   */
  function getProject(id: number, pollInterval: number | undefined = 0) {
    const { result, error, loading, refetch } = useQuery(GET_PROJECT, {
      projectId: id
    }, {
      errorPolicy: 'all',
      pollInterval
    })
    const project : ComputedRef<Project> = computed(() => result?.value?.getProject ?? null)
    return { project, error, loading, refetch }
  }

  /**
   * @description
   * @param {id} projectId
   * @return {*}  
   */
  function getProjects(query: QueryGetProjectsArgs | {} = {}, pollInterval: number | undefined = 0) {
    const { result, error, loading, refetch } = useQuery(GET_PROJECTS, query, {
      errorPolicy: 'all',
      pollInterval
    })

    const projects: ComputedRef<Project[]> = computed(() => result?.value?.getProjects?.projects ?? [])
    const pageInfo: ComputedRef<PageInfo> = computed(() => result?.value?.getProjects?.pageInfo ?? {})
    const timestamp: ComputedRef<string | null> = computed(() => result?.value?.getProjects?.timestamp ?? null)

    return { projects, error, loading, pageInfo, timestamp, refetch }
  }

  /**
   * @description
   * @param {Project} project
   * @return {*}  {Promise<projectMutationResult>}
   */
  async function createProject(project: Project): Promise<projectMutationResult> {
    const result : projectMutationResult = {}
    try {
      // @ts-ignore
      const res = await createProjectMutation(project)
      if(res?.data?.createProject) {
        result.success = true
        result.payload = {
          // @ts-ignore
          project: res?.data?.createProject
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }

  /**
   * @description
   * @param {Project} project
   * @return {*}  {Promise<projectMutationResult>}
   */
  async function updateProject(project: Project): Promise<projectMutationResult> {
    const result : projectMutationResult = {}
    try {
      // @ts-ignore
      const res = await updateProjectMutation(project)
      if(res?.data?.updateProject) {
        result.success = true
        result.payload = {
          // @ts-ignore
          project: res?.data?.updateProject
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }
  
  return { getProject, getProjects, createProject, updateProject }
}
