import L from 'leaflet'
import { evaluateFilter } from '../SceneFilter.js'

const GetJSON = async (file) => {
  let response = await fetch(file, {
    //credentials: 'include'
  });
  let data = await response.json();
  return data;
}

export const getPbf = async (root,dataLayer,key,value,layerProperties,getInfobyFeature) => {
  const layer = L.vectorGrid.protobuf(`${root}/${dataLayer.url}`, dataLayer.options)//.on('click', (e) => { console.log(e)})
  return GetJSON(`${root}/${dataLayer.url}/metadata.json`).then(json => {    
    const bounds = json.bounds.split(',')
    layer.options.bbox = new L.LatLngBounds(new L.LatLng(Number(bounds[1]), Number(bounds[0])), new L.LatLng(Number(bounds[3]), Number(bounds[2])))
    layer.options.metadata = json
    loadPbf(key,value,layerProperties,getInfobyFeature,layer)
    return layer
  })
    .catch(e => {
      return layer
    }) 
}  

export const loadPbf = (key,value,layerProperties,getInfobyFeature,layer) => {
  const overlay = layer.options
  

  function style (feature) {   
    const layer = value.overlays[key]
    const s = layer.style
    const p = feature?.properties
    if (s && s.key && p && (p[s.key] === 0 || p[s.key]) && s.values && s.values[p[s.key]]) {
      s.values[p[s.key]].interactive = s.click
      if (s.values[p[s.key]].weight) {
        s.values[p[s.key]].stroke = true
      }
      return JSON.parse(JSON.stringify(s.values[p[s.key]]))
    }
    else if (s && !s.key && s.value) {
      s.value.interactive = s.click
      if (s.value.weight) {
        s.value.stroke = true
      }
      return JSON.parse(JSON.stringify(s.value))
    }
    else {
      return {
        stroke: true,
        weight: 1,
        opacity: 1,
        color: 'white',          
        fill: true,
        fillOpacity: 0.01
      }
    }
  }            

  function highlightFeature (e) {
    const layer = e.sourceTarget        
    const s = value.overlays[key].style
    let full = false
    if (s && s.highlightFeature) {
      if (layer.options.fullLayer?._path) {
        full = true
        layer._renderer._addPath(layer.options.fullLayer)
        layer.bringToFront()
      } 
      else    
        layer.setStyle(JSON.parse(JSON.stringify(s.highlightFeature)))
    }
    else {
      layer.setStyle({
        color: '#00f',
        fillOpacity: 0.25
      })
    }
    if (!full)
      layer.bringToFront()
  }
  var myIcon = L.divIcon({
    html: '<i class="fas fa-map-pin"></i>',
    iconSize: [20, 20],
    className: 'dummy' 
  })
  
  var counter = 0  
  var currentZoom = value.map.getZoom()
  overlay.options = {}
  overlay.options.ref = value.overlays[key]
  const filter = overlay.options.ref.filter
  const everyNthFeature = filter && filter.reductionCondition && filter.reductionCondition.everyNthFeature ? filter.reductionCondition.everyNthFeature : null  
  overlay.options.pane = `pane${400 + overlay.options.ref.zIndex}`
  overlay.pane = `pane${400 + overlay.options.ref.zIndex}`
  overlay.options.style = style
  overlay.options.onEachFeature = onEachFeature
  overlay.options.filter = (feature) => {
    return !filter ? true : ((everyNthFeature ? counter++ % everyNthFeature === 0 : true) && evaluateFilter(feature, filter))
  }  
  function resetHighlight (e) {
    //value.overlays[key].tileLayer.resetStyle(e.sourceTarget)
    const layer = e.sourceTarget
    if (layer.options.fullLayer?._path) {
      //layer.options.fullLayer.setStyle({stroke: false, fill: false})
    }

    if (layer.options.full) {
      layer.setStyle({stroke: false, fill: false})
    }
    else {
      const layerStyle = style(null)    
      layer.setStyle(layerStyle)
    }
  }

  function getNearest(feature, latlng, overlay, delta = null) {
    const nearest = []
    const layers = feature._renderer._layers
    let x = 0
    let y = 0
    for (const point of feature._parts[0]) {
      x += point.x
      y += point.y
    }
    x/=(feature._parts[0].length-1)
    y/=(feature._parts[0].length-1)                
    for (const layer of Object.keys(layers)) {
      let _x = 0
      let _y = 0
      for (const point of layers[layer]._parts[0]) {
        _x += point.x
        _y += point.y
      }
      _x/=(layers[layer]._parts[0].length-1)
      _y/=(layers[layer]._parts[0].length-1)
      if (Math.abs(x - _x) < delta && Math.abs(y - _y) < delta) {
        nearest.push(layers[layer])
      }          
    }

    return nearest.sort(function(a, b) {
      return (a.properties['ViewId'] > b.properties['ViewId']) ? 1 : ((a.properties['ViewId'] < b.properties['ViewId']) ? -1 : 0);
    });
    
  }

  function getNearestPoint(feature, pos, overlay, delta = null) {
    const nearest = []
    const layers = feature._renderer._layers
   
    const x = feature._point.x
    const y = feature._point.y    
    for (const layer of Object.keys(layers)) {
      const _x = layers[layer]._point.x
      const _y = layers[layer]._point.y
      if (Math.abs(x - _x) < delta && Math.abs(y - _y) < delta && (layers[layer].properties?.Kind === 'aerial')) {
        nearest.push(layers[layer])
      }
    }

    return nearest.sort(function(a, b) {
      return (a.properties['ViewId'] > b.properties['ViewId']) ? 1 : ((a.properties['ViewId'] < b.properties['ViewId']) ? -1 : 0);
    });
  }

  function click (e) {
    //map.fitBounds(e.target.getBounds())        
    const options = e.sourceTarget?._renderer?.options ?? e.target.options    
    const scene = e.sourceTarget?._renderer?.options?.options?.ref?.scene
    const layerMenu = scene.layerMenu
    let layerKey = ''

    const X = e.sourceTarget.properties['EyeX']
    const Y = e.sourceTarget.properties['EyeY']        
    const feature = e.sourceTarget    
    for (const key of Object.keys(value.overlays)) {
      if (('pane' + (400+value.overlays[key].zIndex)) === options.pane) {
        layerKey = key
      }
    }
    const overlay = value.overlays[layerKey]
    switch(layerKey) {      
    case 'imageFootprintsPbf': 
      value.filterLayer = layerKey
      overlay.selectedFile = e.sourceTarget?.properties?.ViewId
      value.imageCompareVisible = true      
      if (!value.shiftPressed && feature._parts) {
        overlay.selectedFile = e.sourceTarget.properties.ViewId
        overlay.nearestFeatures = getNearest(feature, {X, Y}, overlay, 50)
        value.imageCompareVisible = true
      }        
      break
    case 'imageTakePositionsPbf': 

      if (!e.sourceTarget.properties['Kind']) {      
        e.sourceTarget.properties['Kind'] = 'aerial'
      }

      if (e.sourceTarget?.properties?.Kind !== 'aerial') {
        return
      }
      value.filterLayer = layerKey
      overlay.selectedFile = e.sourceTarget?.properties?.ViewId
      overlay.nearestFeatures = getNearestPoint(feature, {X, Y}, overlay, 100)
      value.imageCompareVisible = true
      break    
    }
  }

  function contextmenu (e) {        
    const layer = e.sourceTarget   
    //const feature = layer?.feature
    const layerProp = layer.properties
    const layers = layer._renderer._layers
    let multiPoint = false
    const layerArray = []
    for (const layer of Object.keys(layers)) {
      const properties = layers[layer].properties
      if (properties.EyeX == layerProp.EyeX && properties.EyeY == layerProp.EyeY && properties.EyeZ == layerProp.EyeZ) {
        multiPoint = true
        layerArray.push(layers[layer])
      }
    }    
    const info = getInfobyFeature(key,layer,multiPoint,layerArray)
    layerProperties.menuItems = info.menuItems
    layerProperties.menuTabs = info.menuTabs
    layerProperties.title = info.title
  }

  function empty (e) {

  }

  function onEachFeature (feature, layer) {        
    const s = value.overlays[key].style
    if (s && (s.click || s.highlight)) {
      layer.on({
        mouseover: s.highlight ? highlightFeature : empty,
        mouseout: s.highlight ? resetHighlight : empty,
        click: s.click ? click : empty,
        contextmenu: s.click ? contextmenu : empty
      })
    }
  }
}

const updateLayerData = (data, overlay, value) => {
  if (overlay.tileLayer) {
    value.loader = true
    setTimeout(() => {
      const tileLayer = overlay.tileLayer
      tileLayer.clearLayers()
      tileLayer.options.filter = (feature) => {
        return true
      }
      tileLayer.addData(data)
      value.loader = false}
    , 200)
  }
} 