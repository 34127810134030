import { ComputedRef, computed } from 'vue'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { GET_USERS, GET_USER, GET_ROLES } from '@/apollo/queries/user'
import { PageInfo, QueryGetUsersArgs, Role, User,  }  from '@/gql/graphql'
import { UPDATE_USER} from '@/apollo/mutations/user'

/**
 * @description
 * @interface mutationResult
 */
interface userMutationResult {
  /**
   * @description success
   * @type {boolean}
   * @memberof mutationResult
   */
  success?: boolean,
  /**
   * @description payload
   * @type {{
   *       user: User
   *     }}
   * @memberof mutationResult
   */
  payload?: {
    user: User
  },
  /**
   * @description error
   * @type {*}
   * @memberof mutationResult
   */
  error?: any,

  message?: string
}

export function useUsers() {
  const { mutate: updateUserMutation } = useMutation(UPDATE_USER)

  /**
   * @description
   * @param {id} userId
   * @return {*}  
   */
  function getUser(id: string | number) {
    const { result, error, loading } = useQuery(GET_USER, {
      id: id
    }, {
      errorPolicy: 'all'
    })
    const user : ComputedRef<User> = computed(() => result?.value?.getUser ?? null)
    return { user, error, loading }
  }

  /**
   * @description
   * @param {id} userId
   * @return {*}  
   */
  function getUsers(query: QueryGetUsersArgs | {} = {}) {
    const { result, error, loading, refetch } = useQuery(GET_USERS, query, {
      errorPolicy: 'all'
    })

    const users: ComputedRef<User[]> = computed(() => result?.value?.getUsers?.users ?? [])
    const pageInfo: ComputedRef<PageInfo> = computed(() => result?.value?.getUsers?.pageInfo ?? {})
    const timestamp: ComputedRef<string | null> = computed(() => result?.value?.getUsers?.timestamp ?? null)

    return { users, error, loading, pageInfo, timestamp, refetch }
  }

  /**
   * @description
   * @param {id} userId
   * @return {*}  
   */
  function getRoles() {
    const { result, error, loading } = useQuery(GET_ROLES, {}, {
      errorPolicy: 'all'
    })
    const roles : ComputedRef<Role> = computed(() => result?.value?.getRoles ?? [])
    return { roles, error, loading }
  }

  /**
   * @description
   * @param {User} user
   * @return {*}  {Promise<userMutationResult>}
   */
  async function updateUser(user: User): Promise<userMutationResult> {
    const result : userMutationResult = {}
    try {
      const res = await updateUserMutation(user)
      if(res?.data.updateUser) {
        result.success = true
        result.payload = {
          user: res?.data.updateUser
        }
      } else {
        result.success = false
      }
    } catch (err) {
      result.success = false
      result.error = err;
    }
    return result
  }
  
  return { getUser, getUsers, updateUser, getRoles }
}
