
import { useGlobalQueryLoading, useGlobalMutationLoading } from '@vue/apollo-composable'
import TopProgressBar from './components/UI/TopProgressBar.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  setup() {
    const loading_query = useGlobalQueryLoading()
    const loading_mutation = useGlobalMutationLoading()
    return {
      loading_query,
      loading_mutation,
    }
  },
  components: {
    TopProgressBar
  }
})
