import { Ref, computed } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { GET_LONG_RUNNING_SERVICES, STOP_DATA_BROWSER_SERVICE, RESTART_DATA_BROWSER_SERVICE } from '@/apollo/queries/long_running_services'

/**
 * @description
 * @interface mutationResult
 */
interface DataBrowserMutationResult {
  /**
   * @description success
   * @type {boolean}
   * @memberof mutationResult
   */
  success?: boolean,
  /**
   * @description error
   * @type {*}
   * @memberof mutationResult
   */
  error?: any,
}

export function useLongRunningServices() {
  /**
   * @param {Ref<{
   *     limit?: number
   *     offset?: number
   *     search?: string
   *   }>} input
   * @return {*} 
   */
  function getLongRunningServices(input: Ref<{
    limit?: number
    offset?: number
    search?: string
  }>) {
    const { result, error, loading, refetch } = useQuery(GET_LONG_RUNNING_SERVICES, input, {
      errorPolicy: 'all',
      pollInterval: 2000
    })
    const data = computed(() => result?.value?.getLongRunningServices ?? [])
    return { data, error, loading, refetch }
  }

  const { mutate: stopDataBrowserServiceMutation } = useMutation(STOP_DATA_BROWSER_SERVICE)

  /**
   * @param {number} id
   * @return {*}  {Promise<CdnOriginMutationResult>}
   */
  async function stopDataBrowserService(id: string): Promise<DataBrowserMutationResult> {
    try {
      await stopDataBrowserServiceMutation({ id })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }  
  
  
  const { mutate: restartDataBrowserServiceMutation } = useMutation(RESTART_DATA_BROWSER_SERVICE)
  /**
   * @param {string} id
   * @return {*}  {Promise<CdnOriginMutationResult>}
   */
  
  async function restartDataBrowserService(id: string): Promise<DataBrowserMutationResult> {
    try {
      await restartDataBrowserServiceMutation({ id })
      return { success: true }
    } catch (error) {
      return { success: false, error }
    }
  }  
  
  return { getLongRunningServices, stopDataBrowserService, restartDataBrowserService }
}
