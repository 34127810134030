import { gql } from "@/gql"

export const USER_REGISTER_MICROSOFT = gql(`
  mutation USER_REGISTER_MICROSOFT(
    $accessToken: String!
  ) {
    registerUserMicrosoft(
      accessToken: $accessToken
  ) {
    message
    user {
      id
      roleAlias
      flagActive
      displayName
    }
  }
}
`)


export const USER_LOGIN_MICROSOFT = gql(`
  mutation USER_LOGIN_MICROSOFT(
    $accessToken: String!
  ) {
    loginMicrosoft(
      accessToken: $accessToken
  ) {
    token
    message
    user {
      id
      roleAlias
      flagActive
      displayName
    }
  }
}`)
