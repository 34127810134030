import { GET_STORAGES, GET_STORAGE } from '@/apollo/queries/data_mgmt'
import { Storage } from '@/gql/graphql'
import { useQuery } from '@vue/apollo-composable'
import { ComputedRef, computed } from 'vue'

export function useStorages() {
  
  /**
   * @description returns filtered storages
   * @param {string} storageId
   * @return {*}  
   */
  function getStorages() {
    const { result, loading, error, refetch } = useQuery(GET_STORAGES)
    const storages : ComputedRef<Storage[]> = computed(() => result?.value?.getStorages ?? [])
    return { storages, error, loading, refetch }
  }

  /**
   * @description returns storage by id
   * @param {string} storageId
   * @return {*}  
   */
  function getStorage(storageId: string) {
    const { result, error, loading, refetch } = useQuery(GET_STORAGE, {
      id: storageId,
    }, {
      errorPolicy: 'all'
    })
    const storage : ComputedRef<Storage> = computed(() => result?.value?.getStorage ?? null)
    return { storage, error, loading, refetch: (storageId: string) => refetch({id: storageId}) }
  }



  return {
    getStorage,
    getStorages
  }
}