export enum ByteSize {
  B = 'B',
  KB = 'KB',
  MB = 'MB',
  GB = 'GB',
  TB = 'TB',
  PT = 'PT',
  EB = 'EB',
  ZB = 'ZB',
  YB = 'YB',
}

const conversionFactor : Record<ByteSize, number> = {
  [ByteSize.B]: Math.pow(1024, 0),
  [ByteSize.KB]: Math.pow(1024, 1),
  [ByteSize.MB]: Math.pow(1024, 2),
  [ByteSize.GB]: Math.pow(1024, 3),
  [ByteSize.TB]: Math.pow(1024, 4),
  [ByteSize.PT]: Math.pow(1024, 5),
  [ByteSize.EB]: Math.pow(1024, 6),
  [ByteSize.ZB]: Math.pow(1024, 7),
  [ByteSize.YB]: Math.pow(1024, 8),
}

export function useStrings() {
  /**
   * @description returns string valid for unix file name to use without ""
   * @param {string} inputString
   * @return {*}  {string}
   */
  function generateIdFromName(inputString: string): string {
    const cleanedString = inputString
      .trim() // trim leading and trailing whitespace
      .normalize('NFD') // convert accented characters to their base characters
      .replace(/[\u0300-\u036f]/g, '') // remove diacritical marks
      .replace(/[^\w-]|/g, ''); // remove all characters that are not word characters, hyphens
      
    return cleanedString
  }

  function formatByteSize (size?: number | null, from: ByteSize = ByteSize.MB, to: ByteSize = ByteSize.GB, fixedDecimals: number = 1): string {
    if (!size) {
      return ''
    }
    if (from === to) {
      return size.toString()
    }
    size = size * conversionFactor[from] / conversionFactor[to]
    return (Math.round(size) === size) ? size.toString() : size.toFixed(fixedDecimals)
  }

  return {
    generateIdFromName,
    formatByteSize,
  }
}
